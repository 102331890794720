import React, { Component } from 'react';
import ReactDOM from "react-dom";
import FormInputs from '../../../components/UI/FormInputs';
import { isSet, toQueryString,getLocation,formatPhoneNumberUS, decryptString } from '../../../utils/commonUtils';
import withReduxState from '../../../hoc/wReduxState';
import WithRoutify from '../../../hoc/WithRoutify';
import EmployeeService from '../../../services/EmployeeService';
import VisitsService from '../../../services/VisitsService';
import ActivityCharts from "../../EmployeeVisitContainer/EmployeeVisitDetailView/RightContent/ActivityChart/index";
import Pagination from '../../../components/UI/TableGrid/Pagination';
import AddLab from '../../EmployeeVisitContainer/AddLab';
import AddVaccine from '../../EmployeeVisitContainer/AddVaccine';
import LookupService from '../../../services/LookupService';
import PatientChartsService from '../../../services/PatientChartsService';
import FileManagerService from '../../../services/FileManagerService';
import EmployeeDetails from './EmployeeDetails';
import PhysicalAndDrugScreenFilter from '../PhysicalAndDrugScreenFilter';
import WithRouter from '../../../hoc/WithRouter';
import { PDFDocument } from 'pdf-lib';

class PhysicalAndDrugScreen extends Component {
    constructor(props) {
        super(props);
        // Initialize state or bind methods if needed
        this.state = {
            filters: {
                search_query: ''
            },
            pagination: {
                page: 1,
                perPage: 25,
                totalRecords: 100,
                sort_by: 'visit_date',
                sort_order: 'desc',
                search: {},
            },
            activeEditId: null,
            activeChartDetails: null,
            isModelOpen: false,
            filterOpen: false,
            openAccordionId: null,
            EmployeeVisitDetails: null,
            visitList: {},
            employeeList: [],
            isActivityChartOpen: false,
            activityChartDetails: {},
            activeVisitDetails: {},
            activeEmployeeDetails: {},
            isLabModelOpen: false,
            isVaccineModelOpen: false,
            lookupsOptions: {},
            visitIds:[],
            activityIds:[],
            visitStatusClr: 'blue',
        }
    }

    componentDidMount() {
        const queryParams = new URLSearchParams(this.props.location.search); 
        const { params } = this.props;
        const employeeId = queryParams.get('employee_id');
        if(isSet(employeeId) && this.props.profile.role==='company_employee'){
            this.fetchForSendQuestionnaire(queryParams);
        }
        this.fetchEmployeeList();
        this.fetchLookups();   
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.globalFilter.Physicals!==this.props?.globalFilter?.Physicals) {
            this.fetchEmployeeList();
          }
    }

    componentWillUnmount() {
        document.body.classList.remove('filterOpened')
    }

    handleEvent = () => {
        // Handler for events (e.g., button click)
    };
    onChangeFiltersHandler = (key, val) => {
        const { filters } = this.state;
        this.setState({
            filters: {
                ...filters,
                [key]: val
            }
        })
    }
    render() {
        const { filters, lookupsOptions, openAccordionId, employeeList, visitList, isActivityChartOpen, activityChartDetails, activeVisitDetails, activeEmployeeDetails, isLabModelOpen, isVaccineModelOpen } = this.state;
        return (
            <>
                {
                    isActivityChartOpen ?
                        <ActivityCharts
                            chartDetails={activityChartDetails}
                            isOpen={false}
                            visitDetails={activeVisitDetails}
                            fetchVisitDetails={()=>this.fetchVisitDetails(true)}
                            stateList={this.props.stateList}
                            employeeDetails={activeEmployeeDetails}
                            lookupsOptions={lookupsOptions}
                            companyDetails={activeVisitDetails.company}
                            toogleHanlder={this.activityChartToogleHandler}
                        />
                        :
                        <></>

                }
                {
                    isLabModelOpen ?

                        <AddLab
                            modelTitle={"Add Lab"}
                            isOpen={true}
                            visitId={activityChartDetails.visit_id}
                            mapping_id={activityChartDetails.mapping_id}
                            modalToogleHandler={this.addLabToogleHandler}
                            fetchRecords={()=>this.fetchVisitDetails(true)}
                            fromChart={true}
                        />
                        :
                        ''
                }
                {
                    isVaccineModelOpen ?
                        <AddVaccine
                            modelTitle={"Add Vaccine"}
                            isOpen={true}
                            visitId={activityChartDetails.visit_id}
                            mapping_id={activityChartDetails.mapping_id}
                            modalToogleHandler={this.addVaccineToogleHandler}
                            fetchRecords={()=>this.fetchVisitDetails(true)}
                            fromChart={true}
                        />
                        : ''

                }
                {this.state.filterOpen ? (
                <>
                    {ReactDOM.createPortal(
                    <PhysicalAndDrugScreenFilter
                        onChangeHandler={this.onChangeFiltersHandler}
                        filterData={this.state.filters}
                        closeHandler={this.filterCloseHandler}
                        submitHandler={this.submitFiltersHandler}
                    />,
                    document.getElementById("sh_mainportal_view") // Render the component into the portal container
                    )}
                </>
                ) : (
                ""
                )}


                <div className="sh_cardBox">
                    <div className="sh_cardHeader">
                        <div className="form-group d-flex mb-0 justify-content-between align-items-center">
                            <h6 className="text-xl f-500 mb-0"> Physicals & Drug Screens Result</h6>
                            <div className="d-flex">
                                <div className="form-group-fields row mr-2">
                                    <div className="col-12 px-1 d-flex">
                                        {/* {this.props.params?.filter?'Filter Applied':''} */}
                                        <div className="input-group input-group-joined input-group-solid">
                                        </div>
                                        <div className="col-12">
                                        <div className='btn-group'>
                                                 <div className="input-group input-group-joined input-group-solid">
                                                    <FormInputs
                                                        placeholder="Employee"
                                                        value={filters["search_query"]}
                                                        changeHandler={(val) =>
                                                            this.onChangeFiltersHandler("search_query", val)
                                                        }
                                                        className="form-control"
                                                    />
                                                </div>
                                                 </div>
                                            <div className="btn-group pl-2">
                                                {/* <Buttons
                                                    clickHandler={this.filterOnClick}
                                                    className="btn btn-outline-secondary mr-2"
                                                    acl={"any"}
                                                    label={"Search by "}
                                                    iconType={"Filter"}
                                                ></Buttons>
                                                 */}

                                               
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={() => this.submitFiltersHandler()}
                                                >
                                                    Search
                                                </button>
                                                <button
                                                    className="btn btn-outline-secondary"
                                                    onClick={() => this.clearFiltersHandler()}
                                                >
                                                    Clear
                                                </button>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sh_cardBody p-0">
                        <EmployeeDetails
                            employeeList={employeeList}
                            openAccordionId={openAccordionId}
                            visitList={visitList}
                            fetchChart={this.fetchChart}
                            fetchEmployeeList={this.fetchEmployeeList}
                            fetchVisitDetails={this.fetchVisitDetails}
                            handleAccordinClick={this.handleAccordinClick}
                            printClearanceReport={this.printClearanceReport}
                            printMedicalExaminationPdfs={this.printMedicalExaminationPdfs}
                            printConsentHandler={this.printConsentHandler}
                            orderLabToogleHandler={this.orderLabToogleHandler}
                            activityChartToogleHandler={this.activityChartToogleHandler}
                            deleteHandler={this.deleteHandler}
                        />

                    </div>
                    <Pagination
                        tableRows={{ data: employeeList }}
                        pagination={this.state.pagination}
                        fetchTableRecords={this.fetchEmployeeList}
                    />
                </div>


            </>
        );
    }
    handleAccordinClick = (itemId, employee_id, employeeDetails = {}) => {
        this.setState({ openAccordionId: this.state.openAccordionId === itemId ? null : itemId, activeEmployeeDetails: employeeDetails });
        if (this.state.openAccordionId != itemId) {
            this.fetchEmployeeVisitDetails(employee_id)
        }
    };

    fetchForSendQuestionnaire=async(queryParams={})=>{
        this.props.Loader(true);
        const activityMappingId = queryParams.get('activity_mapping_id');
        const visit_id = queryParams.get('visit_id');
        const visitDetails = await VisitsService.getVisits(visit_id);
        const matchingActivity = await visitDetails[0].mapping_activity.find(
            activity => activity.mapping_id === activityMappingId
        );
        this.setState({activeEmployeeDetails:visitDetails[0].employee,activeVisitDetails:visitDetails[0]},()=>{
            this.activityChartToogleHandler({ ...matchingActivity.activity,mapping_id:activityMappingId, visit_id: visit_id })
        }); 
        this.props.Loader();
    }

    fetchEmployeeVisitDetails = async (employee_id,isReload=false) => {
        this.props.Loader(true);
        const updatedTableData = [...this.state.employeeList];
        const index = updatedTableData.findIndex(item => item.employee_id == employee_id);
        if (index !== -1 && (updatedTableData[index].visit_data === null || isReload===true)) {
            let VisitsList = {};
            if(isSet(this.state.visitIds)){
                VisitsList = await EmployeeService.getEmployeeVisitsHistoryByVisitIds(this.state.visitIds,employee_id);
            }else{
                VisitsList = await EmployeeService.getEmployeeVisitsHistoryForPhysical(employee_id);
            }
            
            const visitData = VisitsList.data.data;
            visitData.forEach(itm => {
                let { overall_status } = itm;
                let statusClr = 'blue';
                if (overall_status.toLowerCase() === 'passed') {
                    statusClr = 'green';
                } else if (overall_status.toLowerCase() === 'failed') {
                    statusClr = 'red';
                }
                itm.statusClr = statusClr;
            });
            updatedTableData[index].visit_data = visitData;
            this.setState({ employeeList: updatedTableData })
        }
        this.props.Loader(false);
    }
    fetchChart = async (visitId, visitDetails = {}) => {
        this.props.Loader(true);
        const { visitList } = this.state;
        if (!visitList.hasOwnProperty(visitId) || visitList[visitId] == null) {
            let data = {};
            if(isSet(this.state.activityIds)){
                data = await VisitsService.getActivityByActivityIds(this.state.activityIds,visitId);
            }else{
                data = await VisitsService.getVisits(visitId);
            }
            
            const updatedData = { ...data };
            updatedData && updatedData[0] && updatedData[0].mapping_activity.forEach((item, index) => {
                item.statusClr = 'blue';
                if (item.status == 'Completed/Administrated') {
                    item.status = 'passed';
                }
                if (item.status == 'Ordered') {
                    item.status = 'pending'
                }

                if (item.status.toLowerCase() === 'passed') {
                    item.statusClr = 'green';
                } else if (item.status.toLowerCase() === 'failed') {
                    item.statusClr = 'red';
                }
            });
            this.setState({ visitList: { ...this.state.visitList, ...{ [visitId]: updatedData } } });
            // console.log('state', this.state.visitList);
        }
        this.props.Loader(false);
        this.setState({ activeVisitDetails: visitDetails })

    }
    activityChartToogleHandler = (chartDetails = {}) => {
        this.setState({ isActivityChartOpen: !this.state.isActivityChartOpen, activityChartDetails: chartDetails });
    }
    fetchVisitDetails = async (isReloadVisit=false) => {
        const { activeVisitDetails } = this.state;
        const visit_id = activeVisitDetails.visit_id;
        this.setState({ visitList: { ...this.state.visitList, [visit_id]: null } }, () => { 
            this.fetchChart(visit_id, activeVisitDetails);
            if(isReloadVisit===true){
                this.fetchEmployeeVisitDetails(activeVisitDetails.employee_id,isReloadVisit); 
            }
        });
    }
    orderLabToogleHandler = (type = '', chartDetails = {}) => {
        if (type =='Lab' || type=='Lab - Point of Care') {
            this.addLabToogleHandler(chartDetails);
        } else if (type =='Vaccine') {
            this.addVaccineToogleHandler(chartDetails);
        }
    };
    addVaccineToogleHandler = (chartDetails = {}) => {
        this.setState({ isVaccineModelOpen: !this.state.isVaccineModelOpen, activityChartDetails: chartDetails })
    }
    addLabToogleHandler = (chartDetails = {}, chartOpen = false) => {
        this.setState({ isLabModelOpen: !this.state.isLabModelOpen, activityChartDetails: chartDetails });
    }
    submitFiltersHandler = async () => {
        const { filters, pagination } = this.state;
        const queryfilters = {
            ...pagination,
            search: filters,
        }
        this.fetchEmployeeList(queryfilters);
        this.setState({ openAccordionId : null,
                        employeeList:[],
                        visitList:{},
                        activeVisitDetails: {},
                        activeEmployeeDetails: {}, 
                        activityChartDetails: {}, 
                    });
    }
    clearFiltersHandler = () => {
        this.setState({
            filters: {}
        }, this.submitFiltersHandler);
    }
    fetchEmployeeList = async (query = {}) => {
        const { tableRecords, pagination } = this.state;
        const {params}=this.props;
        let queryString = toQueryString({ ...pagination, ...query });
        const globalFilter=this.props?.globalFilter?.Physicals || {};
        if (params?.filter) {
            const qfilter = decryptString(params.filter);
            queryString = {...queryString,...qfilter};
        }
        queryString = {...queryString,...globalFilter};
        try {
            this.props.Loader(true);
            const EmployeeList = await EmployeeService.getEmployeeListForPhysical(queryString);
            let employeeList = [];

        
            EmployeeList.data.data.forEach((item, index) => {
                employeeList = [
                    ...employeeList,
                    {
                        employee_id: item.employee_id,
                        first_name: item.first_name,
                        last_name: item.last_name,
                        dob: item?.dob,
                        company_id: item.company_id,
                        clinic_id: item.clinic_id,
                        clinic_department_id: item.clinic_department_id,
                        system_id: item.system_id,
                        company: item.company || '--',
                        visit_data: null,
                        active: 'Yes',
                    }
                ]
            });
            const updatePagination = (prevStatePagination, newPagination) => ({
                page: newPagination.page || prevStatePagination.page,
                perPage: newPagination.perPage || prevStatePagination.perPage,
                totalRecords: EmployeeList.pagination.totalCount,
                sort_by: newPagination.sort_by || prevStatePagination.sort_by,
                sort_order: newPagination.sort_order || prevStatePagination.sort_order,
            });
            this.setState({
                employeeList: employeeList,
                activityIds: EmployeeList.activity_ids,
                visitIds : EmployeeList.visit_ids,
                pagination: {
                    ...this.state.pagination,
                    ...updatePagination(this.state.pagination, query),
                }
            });
            this.props.Loader();
        } catch (e) {
            this.props.Loader();
        }
    }
    onChangeFiltersHandler = (key, val) => {
        const { filters } = this.state;
        this.setState({
            filters: {
                ...filters,
                [key]: val
            }
        });
    }
    printClearanceReport = async (visitId) => {
        const { params, Loader } = this.props;
        Loader(true);
        try {
            const data = await PatientChartsService.getClearanceReportPDF(null, visitId);
            const pdfData = {
                file_content: data,
                original_name: `clearance-report`,
                mime_type: 'application/pdf'

            }
            FileManagerService.printPdf(pdfData);
        } catch (e) {
            console.log('e', e);
        }
        Loader(false);
    }

    
    printMedicalExaminationPdfs = async (mappingId,type,pdfName)=>{
        const {params,Loader} = this.props;
        // const visitId = params.visitId;
        Loader(true);
        try{
          let data, existingPdfBytes;
          if(type== 'CERT'){
            data = await PatientChartsService.getMedicalExaminerCertificatePDFData(null,mappingId);
          }else if (type== 'DOT'){
            data = await PatientChartsService.getMedicalExaminationReportPDFData(null,mappingId);
          }
          existingPdfBytes = await fetch(`/assets/pdfs/${pdfName}.pdf`).then(res => res.arrayBuffer());
            console.log('Fetching PDF...');
            console.log(data);
            console.log('Fetched PDF file successfully:', existingPdfBytes);
            const pdfDoc = await PDFDocument.load(existingPdfBytes);
            const form = pdfDoc.getForm();
            const fields = form.getFields();
    
        //   fields.forEach(field => {
        //   const name = field.getName();
        //   console.log('Field name:', name);
        //   console.log(field.constructor.name );
        // });
    
     Object.keys(data).forEach((key) => {
        try{
      const field = form.getField(key); // Get the field using the key
    
      if (field) {
        let value =  data[key].toString() ; // Ensure the value is a string or fallback

        const name = field.getName();
        //   console.log('Field name:', name);
        //   console.log(field.constructor.name );
        // console.log(value);

        if(name === 'examPhone' || name === 'driverPhone' || name === 'medicalPhone' || name === 'medicalPhoneState'){
            console.log('name' , name , value);
            value = formatPhoneNumberUS(value);
             console.log('name' , name , value);
           }
           

        if (value !== '--') {
        // Check field type using field.constructor.name
        switch (field.constructor.name) {
          case 'PDFTextField':  
          try{ 
              field.setText(value);
            }catch(e){
              console.log('e',field.getName(),field.constructor.name,e);
            }
            break;
          case 'PDFDropdown':
            try{ 
    // Function to select the dropdown value
    const selectDropdownValue = (field, value) => {
      const dropdownOptions = field.getOptions(); // Get all dropdown options
      
      // Get the full name from the abbreviation if it's a state; otherwise, use the value directly
      const stateKeyValue = getLocation(value); // e.g., { AK: 'Alaska' } or null
      const fullName = stateKeyValue ? Object.values(stateKeyValue)[0] : value;
    
      // console.log(`The key is: ${key}`);
    
    if(stateKeyValue){
      const key = Object.keys(stateKeyValue)[0]; // Extracts 'AK'
     if (dropdownOptions.includes(key)) {
      field.select(key);
    } else {
        console.warn(`The key '${key}' is not available in the dropdown.. Field Name - '${field.getName()}'`);
        console.warn(`Dropdown options - '${dropdownOptions}'`);
    }
    }
      // Check if the dropdown contains the passed value (e.g., 'AK' or 'fight')
      if (dropdownOptions.includes(value)) {
        field.select(value);
      }
      // Check if the dropdown contains the full name (e.g., 'Alaska')
      else if (dropdownOptions.includes(fullName)) {
        field.select(fullName);
      }
      // Log a warning if neither is found
      else {
        console.warn(`Value '${value}' or its full name '${fullName}' is not available in the dropdown.`);
      }
    };
    
    // Example Usage for States
    selectDropdownValue(field, value);    
    }catch(e){
      console.log('e',field.getName(),field.constructor.name,e);
    }
            break;
          case 'PDFCheckBox':
            // Check/uncheck a checkbox based on the value
            try{
            if (value === 'Yes' || value === 1 || value === '1') {
              field.check();
            } else {
              field.uncheck();
            }
          }catch(e){
            console.log('e',field.getName(),field.constructor.name,e);
          }
            break;
          case 'PDFRadioGroup':
    
            try{
                const options = field.getOptions(); 
                console.log(options);     
                field.select(value);
                           
          }catch(e){
            console.log('e',field.getName(),field.constructor.name,e);
          }
             
            break;
          default:
            console.warn(`Field '${key}' is of unsupported type: ${field.constructor.name}`);
            break;
          }
       }
      } else {
        console.warn(`Field '${key}' not found in the form.`);
      }
    }catch(e){
        console.log('e',e);
      }
    });
    if (type == 'CERT') {
        try{
        if(data.examinerSignature && data.examinerSignature !== '--'){
          //  Embed the signature image (PNG or Base64)
          const signatureBytes = await fetch(data.examinerSignature).then(res => res.arrayBuffer());
          const signatureImageEmbed = await pdfDoc.embedPng(signatureBytes);

          const page = pdfDoc.getPage(0); // Specify the page number where you want to insert the signature
          page.drawImage(signatureImageEmbed, {
            x: 160,  // X coordinate where the signature will appear
            y: 274,  // Y coordinate for the signature
            width: 100,  // Signature image width
            height: 30,  // Signature image height
          });
        }
        if(data.driverSignature && data.driverSignature !== '--'){
          //  Embed the signature image (PNG or Base64)
          const signatureBytes = await fetch(data.driverSignature).then(res => res.arrayBuffer());
          const signatureImageEmbed = await pdfDoc.embedPng(signatureBytes);

          const page = pdfDoc.getPage(0); // Specify the page number where you want to insert the signature
          page.drawImage(signatureImageEmbed, {
            x: 130,  // X coordinate where the signature will appear
            y: 130,  // Y coordinate for the signature
            width: 100,  // Signature image width
            height: 30,  // Signature image height
          });
        }
      }catch(e){
        console.log('e',e);
      }
      } else if (type == 'DOT') {
         try{
        if(data.driverSignature && data.driverSignature !== '--'){
          //  Embed the signature image (PNG or Base64)
          const signatureBytes = await fetch(data.driverSignature).then(res => res.arrayBuffer());
          const signatureImageEmbed = await pdfDoc.embedPng(signatureBytes);

          const page = pdfDoc.getPage(1); // Specify the page number where you want to insert the signature
          page.drawImage(signatureImageEmbed, {
            x: 130,  // X coordinate where the signature will appear
            y: 183,  // Y coordinate for the signature
            width: 60,  // Signature image width
            height: 18,  // Signature image height
          });
        }
        if(data.amendedExaminerSignature && data.amendedExaminerSignature !== '--'){
          //  Embed the signature image (PNG or Base64)
          const signatureBytes = await fetch(data.amendedExaminerSignature).then(res => res.arrayBuffer());
          const signatureImageEmbed = await pdfDoc.embedPng(signatureBytes);

          const page = pdfDoc.getPage(3); // Specify the page number where you want to insert the signature
          page.drawImage(signatureImageEmbed, {
            x: 250,  // X coordinate where the signature will appear
            y: 510,  // Y coordinate for the signature
            width: 60,  // Signature image width
            height: 20,  // Signature image height
          });
        }
        if(data.examinerSignatureFederal && data.examinerSignatureFederal !== '--'){
          //  Embed the signature image (PNG or Base64)
          const signatureBytes = await fetch(data.examinerSignatureFederal).then(res => res.arrayBuffer());
          const signatureImageEmbed = await pdfDoc.embedPng(signatureBytes);

          const page = pdfDoc.getPage(3); // Specify the page number where you want to insert the signature
          page.drawImage(signatureImageEmbed, {
            x: 180,  // X coordinate where the signature will appear
            y: 425,  // Y coordinate for the signature
            width: 70,  // Signature image width
            height: 20,  // Signature image height
          });
        }
        if(data.examinerSignatureState && data.examinerSignatureState !== '--'){
          //  Embed the signature image (PNG or Base64)
          const signatureBytes = await fetch(data.examinerSignatureState).then(res => res.arrayBuffer());
          const signatureImageEmbed = await pdfDoc.embedPng(signatureBytes);

          const page = pdfDoc.getPage(4); // Specify the page number where you want to insert the signature
          page.drawImage(signatureImageEmbed, {
            x: 160,  // X coordinate where the signature will appear
            y: 524,  // Y coordinate for the signature
            width: 70,  // Signature image width
            height: 20,  // Signature image height
          });
        }
      }catch(e){
        console.log('e',e);
      }
      
      }

    const pdfBytes = await pdfDoc.save();
    
    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
  
    link.download = type === 'CERT' ? 'MedicalCertificate.pdf' : type === 'DOT' ? 'MedicalReport.pdf' : 'default.pdf';        
    link.click();

}catch (e) {
  console.error('Error occurred while processing the PDF:', e);
} finally {
  Loader(false); // Ensure the loader is hidden even if an error occurs
}
}

    printConsentHandler = async (labelName, visitId) => {
        const { params, Loader } = this.props;
        const { activityChartDetails } = this.state;
        const activity_name = activityChartDetails.activity_name;
        const queryfilters = {
            activity_name: (activity_name),
        }
        Loader(true);
        try {
            const data = await PatientChartsService.getPrintConsent(null, queryfilters, visitId);
            const pdfData = {
                file_content: data,
                original_name: `${labelName}`,
                mime_type: 'application/pdf'

            }
            FileManagerService.printPdf(pdfData);
        } catch (e) {
            console.log('e', e);
        }
        Loader(false);
    }
    deleteHandler = async (id) => {
        // const { activeChartDetails } = this.state;
        VisitsService.deleteActivityInChartInVisits(id)
            .then((res) => {
                //toast and show error logic
                this.fetchVisitDetails(true);
                this.props.Toast.success('Activities Deleted Successfully');
            })
            .catch((err) => {
                //
            })
            .finally(() => {
                //
            });
    }
    filterOnClick = () => {
        this.setState({
            filterOpen: !this.state.filterOpen,
        }, () => {
            if (document.body.classList.contains('filterOpened')) {
                document.body.classList.remove('filterOpened');
                // menuToggleElement.removeClass('active');
            } else {
                document.body.classList.add('filterOpened');
            }
        });
    };
    filterCloseHandler = (isClear = true) => {
        if (isClear) {
            this.clearFiltersHandler();
        }
        else {
            this.setState({
                filterOpen: false,
            }, () => {
                if (document.body.classList.contains('filterOpened')) {
                    document.body.classList.remove('filterOpened');
                    // menuToggleElement.removeClass('active');
                } else {
                    document.body.classList.add('filterOpened');
                }
            });
        }
    };
    fetchLookups = async () => {
        const [chartResult, shippingService] = await Promise.all([
            LookupService.getLookupByKey('chart_result'),
            LookupService.getLookupByKey('shipping_service'),
        ]);
        let shippingServiceOptions = [{ label: '-None-', value: '' }];
        isSet(shippingService,[]).forEach((item) => {
            shippingServiceOptions.push({
                label: item.value,
                value: item.value
            });
        });
        let chartResultOptions = [{ label: '-None-', value: '' }];
        isSet(chartResult,[]).forEach((item) => {
            chartResultOptions.push({
                label: item.value,
                value: item.value
            });
        });
        this.setState({
            lookupsOptions: {
                shippingService: shippingServiceOptions,
                chartResult: chartResultOptions
            }
        })
    }

}

export default withReduxState(WithRouter(WithRoutify(PhysicalAndDrugScreen)));