import { Modal } from "react-bootstrap";
import FormInputs from "../../components/UI/FormInputs";
import { useState } from "react";
import WithRoutify from "../../hoc/WithRoutify";
import VisitsService from "../../services/VisitsService";
import { isObject, isSet } from "../../utils/commonUtils";
import WithRouter from "../../hoc/WithRouter";
import { useLocation } from "react-router-dom";
import FileManagerService from "../../services/FileManagerService";

const PdfResultContainer = (props) => {
    const [formData, setFormData] = useState({});
    const [validation, setValidation] = useState({});
    const query = new URLSearchParams(useLocation().search);
    const [step,setStep]=useState(1);
    const isPhoneValidation=query.get('phone')=='true'?true:false
    const changeHandler = (fieldName, value) => {
        setValidation({ ...validation, [fieldName]: '' })
        setFormData({ ...formData, [fieldName]: value });
    }
    const submitHandler=async()=>{
        const payload={
            visit_id:query.get("visit_id"),
            result_type:query.get('result_type'),
            id:query.get('id'),
            patient:query.get('patient'),
            isPhoneValidation:isPhoneValidation,
            step:step,
            phone:formData.phone,
            email:formData.email,
            code :formData.code ||'',
        }
        let updateValidation={...validation};
        const data=await VisitsService.getEmailPdfResult(payload);
        if (data.status && data.status === "ERROR") {
            if (isObject(data.data)) {
              for (let key in data.data) {
                updateValidation = {
                  ...updateValidation,
                  [key]: data.data[key].join(","),
                };
              }
            } else {
              props.Toast.error(data.data);
            }
            if (Object.keys(updateValidation).length > 0) {
              setValidation(updateValidation);
            }
          }
          else if (step==2) {
            console.log("verification completed",data.data);
            const pdfData = {
                file_content:data.data,
                original_name:`${'name'}`,
                mime_type:'application/pdf'
                
            }
            FileManagerService.printPdf(pdfData);
            setStep(1);
            setFormData({});
          }
          else{
            setStep(step+1);
          }
    }
    return (
        <>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6 min-vh-100 d-flex flex-column justify-content-center mx-auto">
                        <div className="row">
                          <div className="col-md-9 mx-auto">
                        <div className="card sh_loginCard o-hidden border-0 my-5 shadow_new">                                                        
                            <div className="card-body">
                                <div className="row">  
                                 <div className="col-lg-12"> 
                                <div className="text-center sh_loginLogo">
                                    <img src="/assets/img/snap-logo-new.png" className=" w-100" alt="Logo" />
                                </div>
                                <h6 className="fw-light mb-0">Verification</h6>
                                <form className="sh_loginCre" onSubmit={(e) => { e.preventDefault(); submitHandler(); }}>
                                    {
                                        step===1?
                                        <>
                                        <FormInputs
                                        type={isPhoneValidation?'Phone':''}
                                        className="form-control form-control-fields"
                                        placeholder={isPhoneValidation?'Enter Phone Number':'Enter Email Address'}
                                        name={isPhoneValidation?'phone':'email'}
                                        changeHandler={(value, e) => { changeHandler(isPhoneValidation?'phone':'email', value) }}
                                        value={formData[isPhoneValidation?'phone':'email']}

                                    />
                                      {isSet(validation[query.get('patient')=='true'?'phone':'email']) !== "" ? (
                                            <span className="text-danger m-1">
                                              {isSet(validation[isPhoneValidation?'phone':'email'], "")}
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                    <div className="d-flex align-items-start mt-0">
                                        <i className="fas fa-info-circle pt-2 fa-lg"></i>
                                        <p className="font-weight-normal text-gray-600 text-sm pl-2">
                                            {`Please enter your ${isPhoneValidation ? 'phone number' : 'email address'} to receive a verification code. This code will be used to verify your identity and secure your account.`}  
                                        </p>
                                    </div>
                                        </>
                                        :
                                        <>
                                        <FormInputs
                                        type=""
                                        className="form-control form-control-fields"
                                        placeholder="Enter Code"
                                        name="code"
                                        changeHandler={(value, e) => { changeHandler('code', value) }}
                                        value={formData['code']}

                                    />
                                        {isSet(validation['code'], "") !== "" ? (
                                            <span className="text-danger m-1">
                                              {isSet(validation['code'], "")}
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                            <div className="d-flex align-items-start mt-0">
                                        <i className="fas fa-info-circle pt-2 fa-lg"></i>
                                        <p className="font-weight-normal text-gray-600 text-sm pl-2">
                                            {`Please check your ${isPhoneValidation ? 'phone number' : 'email address'} for the Verification Code and enter it above.`} 
                                        </p>
                                    </div>
                                    </>
                                    }
                                    <div className="d-flex align-items-center justify-content-center sh_loginButtons">
                                        <a class="btn btn-primary btn-user"  onClick={()=>submitHandler()}>Submit</a>
                                    </div>
                                </form>
                            </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default WithRouter(WithRoutify(PdfResultContainer));