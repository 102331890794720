import React from 'react';

const  DeleteIconDetailView = ({fill='#F44336'}) => (
    <svg width="16" height="16" viewBox="0 0 16 16">
    <path fill={fill} d="M13.071,5.807l-0.248,7.487c-0.043,1.332-1.14,2.39-2.473,2.385H5.65c-1.333,0.005-2.429-1.051-2.474-2.383L2.929,5.807C2.918,5.465,3.185,5.179,3.527,5.167c0.341-0.011,0.627,0.257,0.639,0.598l0.247,7.488c0.026,0.666,0.572,1.19,1.237,1.19h4.701c0.665,0,1.212-0.526,1.236-1.192l0.247-7.486c0.012-0.341,0.298-0.609,0.64-0.598C12.813,5.179,13.083,5.465,13.071,5.807z M13.889,3.315c0,0.341-0.275,0.618-0.618,0.618H2.73c-0.342,0-0.619-0.276-0.619-0.618c0-0.342,0.277-0.619,0.619-0.619h1.917c0.406,0.001,0.746-0.306,0.787-0.71C5.529,1.04,6.327,0.318,7.279,0.321h1.441c0.952-0.003,1.751,0.719,1.845,1.666c0.042,0.405,0.382,0.711,0.787,0.71h1.916C13.611,2.697,13.888,2.974,13.889,3.315L13.889,3.315z M6.508,2.697h2.985c-0.08-0.186-0.134-0.383-0.158-0.584C9.303,1.797,9.038,1.557,8.722,1.557H7.28c-0.317,0-0.583,0.241-0.614,0.556C6.642,2.314,6.589,2.511,6.508,2.697L6.508,2.697z"></path>
    <path fill={fill}  fill-opacity="0.5" d="M7.13,12.066V6.801c0-0.342-0.276-0.618-0.618-0.618c-0.342,0-0.619,0.276-0.619,0.618v5.268c0,0.342,0.277,0.619,0.619,0.619c0.341,0,0.618-0.277,0.618-0.619V12.066z"></path>
    <path fill={fill}  fill-opacity="0.5" d="M10.107,12.066V6.801c0-0.342-0.276-0.618-0.618-0.618S8.871,6.458,8.871,6.801v5.268c0,0.342,0.276,0.619,0.618,0.619s0.618-0.277,0.618-0.619V12.066z"></path>
  </svg>
);

export default DeleteIconDetailView;