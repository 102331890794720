import React, { useState } from "react";
import { _t } from "../../utils/i18nUtils";
import { isObject, isSet} from "../../utils/commonUtils";
import {
  useNavigate,
} from "react-router-dom";
import AuthService from "../../services/AuthService";
import FormInputs from "../../components/UI/FormInputs";
import Card from "../../components/UI/Card";
import UserService from "../../services/UserService";
import { useDispatch } from 'react-redux';
import {setProfileDetails,setProfileStateList} from '../../store/SessionSlice';
import WithRoutify from '../../hoc/WithRoutify';
import Buttons from "../../components/UI/Buttons";
import OrganizationService from "../../services/OrganizationService";
import { setSessionIsActive } from "../../store/CommonSlice";
import i18n from "../../i18n/config";
import WithRouter from "../../hoc/WithRouter";
import { acl_check } from "../../utils/aclUtils";

const LoginForm = (props) => {
  const {Loader}=props
  const [validation,setValidation] = useState("");
  // const query= new URLSearchParams(useLocation().search);
  const [formdata,setFormData]=useState({
    'email':'',
    'password':'',
  });
  const [formValidation,setFormValidation] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();  
  //onchange event
  const handleOnChange=(value,{e})=>{
    setFormData({...formdata,[e.target.name]:value});
    setFormValidation({...setFormValidation,[e.target.name]:''})
    setValidation('');
  }
//login
  const handleLogin = async () => {
    // console.group("Login");
    const {email,password}=formdata;
    const credentials = {email,password};
    
    Loader(true);
    try{
      const userData    = await AuthService.login(credentials);
      let updateValidation = {};
      if(userData.status && userData.status==='ERROR'){
        Loader(false);
        if (isObject(userData.data)) {
          for (let key in userData.data) {
            updateValidation = {
              ...updateValidation,
              [key]: userData.data[key]
            };
          }
          if (Object.keys(updateValidation).length > 0) {
            setFormValidation(updateValidation);
          }
        } 
        if (userData.data.error) {
            setValidation(isSet(userData.data.error,'Authentication failed')+'  The information you have provided cannot be authenticated. Check your login information and try again');
  
        }
       return; 
      }
      const isAuthenticated = AuthService.isAuthenticated();
      if (isAuthenticated === true) {
        dispatch(setSessionIsActive(true));
        const profileData = await UserService.getUserProfile();
        if(isSet(profileData.organization_id,null)!==null){
          OrganizationService.storeOrgId(profileData.organization_id);
          OrganizationService.storeTimeZone(profileData.organization_id);
        }
        i18n.changeLanguage(profileData.preferred_language);
        AuthService.storePrefLanguage(profileData.preferred_language);
        // console.log('profileData',profileData.preferred_language);
        // if(isSet(profileData) && !isSet(profileData.role[0])){
        //   Employeeervice.storeEmployeeId(profileData.employee_id);
        // }
        // console.log('profileData',profileData);
        AuthService.storeProfileId(profileData.id);
        dispatch(setProfileDetails({ key: profileData.id, value:profileData }));
        Loader(false);
        let stateListResponse=await UserService.getUserStateList();
        let states=[{label:'-Select State-',value:''}];
        stateListResponse&&stateListResponse.forEach((val)=>{
          states.push({label:val.code,value:val.code})
        });
        const {permissions,is_multiple_organization} = profileData;
        let redirectUrl = '/dashboard';
        if(isSet(is_multiple_organization,false)===true && acl_check(permissions,'organization-list')===true){
          redirectUrl = '/organisation';
        }
        else if(acl_check(permissions,'dashboard-list')===true){
          redirectUrl = '/dashboard';
        }
        else if(acl_check(permissions,'appointment-list')===true){
            redirectUrl = '/appointments';
        }else if(acl_check(permissions,'company-list')===true){
            redirectUrl = '/company';
        }else if(acl_check(permissions,'clinic-list')===true){
            redirectUrl = '/clinics';
        }else if(acl_check(permissions,'employee-list')===true){
            redirectUrl = '/employee';
        }else if(acl_check(permissions,'visits-list')===true){
            redirectUrl = '/visits';
        }else if(acl_check(permissions,'lab-list')===true){
            redirectUrl = '/labs';
        }else if(acl_check(permissions,'user-list')===true){
            redirectUrl = '/users';
        }else if(acl_check(permissions,'role-list')===true){
            redirectUrl = '/roles';
        }
        dispatch(setProfileStateList(states));
        const redirectPath = props.location.pathname.includes('send-questionnaire') ? `/result/physical-drug-screens${props.location.search}` : redirectUrl;
        navigate(redirectPath);        
      }
    }catch(e){
      // showToast('error',"An error occurred");
      Loader();
    }
    
    
    //api/user_profile
    // console.log("Logging in with:", userData, AuthService.getToken());
  };
  const footerBtn = {
    type:'BtnList',
    buttonList:[
      {
        clickHandler:handleLogin,
        className:"btn btn-primary btn-user",
        acl:'any',
        label:_t('login'),
      },
      {
        clickHandler:() => navigate('/self-register'),
        className:"btn btn-primary btn-user",
        label:_t('SelfRegister'),
        acl:'any',
      }
    ]
  }
  
  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      // event.preventDefault();
      handleLogin();
    }
  };

  // useEffect(() => {
  //   const keyDownHandler = event => {
  //     if (event.key === 'Enter') {
  //       event.preventDefault();
  //       handleLogin();
  //     }
  //   };
  //   //handle to Enter-key press in form
  //   document.addEventListener('keydown', keyDownHandler);
  //   return () => {
  //     document.removeEventListener('keydown', keyDownHandler);
  //   };
  // }, [formdata]);
  return (
      
        <div className="row justify-content-center">
          <div className="col-lg-6 min-vh-100 d-flex flex-column justify-content-center mx-auto">
            <div className="row">
              <div className="col-md-9 mx-auto">
                <Card className="sh_loginCard o-hidden border-0 my-5 shadow_new">
                    <div className="row">
                      <div className="col-lg-12">
                     
                        <div className="text-center sh_loginLogo">
                        <img src="/assets/img/snap-logo-new.png" className="w-100" alt="logo"/>
                        </div>
                        {isSet(validation,'')!=='' ? <p className="text-danger m-1" style={{fontSize:'12px'}}>{isSet(validation,'')}</p> : ''}
                        <form className="sh_loginCre">
                          <div className="form-group">
                            {/* <input type="email" className="form-control form-control-user" id="" aria-describedby="" placeholder="Email Address"/> */}
                            <FormInputs
                              type="TextInput"
                              value={formdata.email}
                              name="email"
                              changeHandler={handleOnChange}
                              style={styles.input}
                              onKeyPressHandler={handleKeyPress}
                              className="form-control form-control-user"
                              placeholder={_t('emailAddress')}
                            />
                            {formValidation['email']?
                            <span className="text-danger m-1">{formValidation['email']}</span>
                            :
                            <></>
                            }
                          </div>

                          <div className="form-group">
                            <FormInputs
                              type="Password"
                              name="password"
                              value={formdata.password}
                              changeHandler={handleOnChange}
                              style={styles.input}
                              onKeyPressHandler={handleKeyPress}
                              className="form-control form-control-user"
                              placeholder={_t('password')}
                            />
                            {formValidation['password']?
                            <span className="text-danger m-1">{formValidation['password']}</span>
                            :
                            <></>
                            }
                          </div>

                          <div className="form-group sh_loginButtons">
                            <div className="d-flex align-items-center justify-content-between">
                              <Buttons {...footerBtn} />
                            </div>
                          </div>
                        </form>

                        <div className="text-left">
                          <Buttons 
                            href='/login/forgetPassword'
                            className="action-text"
                            acl='any'
                            label={`${_t('ForgotPassword')}/${_t('FirstTimeUser')}`} />
                        </div>
                        <div className="text-left">
                          <hr className="my-2" />
                          <p>
                            {_t('loginTerms')}
                          </p>
                        </div>
                      </div>
                    </div>
                </Card>
              </div>
            </div>
          </div>
        </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    width: "300px",
  },
  label: {
    marginBottom: "5px",
  },
  input: {
    marginBottom: "15px",
    padding: "8px",
  },
  button: {
    backgroundColor: "#544CF9",
    color: "white",
    padding: "10px",
    cursor: "pointer",
  },
};

export default WithRouter(WithRoutify(LoginForm));
