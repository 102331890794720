import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import Buttons from "../../../components/UI/Buttons";
import WithRoutify from "../../../hoc/WithRoutify";
import { fetchTimeInterval, isObject, isSet } from "../../../utils/commonUtils";
import FormInputs from "../../../components/UI/FormInputs";
import VisitsService from "../../../services/VisitsService";
import LookupService from "../../../services/LookupService";
import { VISIT_CATEGORY, VISIT_STATUS } from "../../../utils/constant";
import EditClinicVisitCategoryConsentForm from "../EditClinicVisitCategoryConsentForm";
import ConsentFormService from "../../../services/ConsentFormService";
import OrganizationService from "../../../services/OrganizationService";
import FileManagerService from "../../../services/FileManagerService";
import moment from 'moment';
import VisitDetailsTab from "./VisitDetailsTab";
import TimelineTab from "./TimeLineTab";
import withReduxState from "../../../hoc/wReduxState";
import { createPortal } from "react-dom";
import Alert from "../../../components/UI/ConfirmBox/Alert";
import { acl_check } from "../../../utils/aclUtils";
import PERMISSIONS from "../../../utils/AclPermissions";
import Acl from "../../../hoc/Acl";


const MAIN_TABS = [
  {
    label: "Visit Details", component: VisitDetailsTab,
  },
  { label: "TimeLine", component: TimelineTab },

];
class VisitDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 0,
            formData: {},
            validations: {},
            visitCategoryOptions:[],
            visitStatusOption:[],
            isEditConsentModelOpen: false,
            organizationLogo:"",
            isTextAreaEnabled: {
              surgery_explanation: true,
              taking_medication_explanation: true,
              other_conditions_description: true,
              additional_comments_description: true
            },
            showConsentButtons:false,
            snapClinicConsentForm:{},
            snapVisitConsentForm:{},
            snapCompanyDetails:{},
            snapEmployeeDetails:{},
            visitTimeline:[],
            hasDataChanges:false,
            showAlert:false,

        };
        this.patientHealthInformation = [
          {label:'1. Head/brain injuries (e.g., concussion, seizures)',name:'head_brain_injuries'},
          {label:'2. Seizures, epilepsy',name:'seizures_epilepsy'},
          {label:'3. Eye problems (except glasses or contacts)',name:'eye_problems'},
          {label:'4. Ear and/or hearing problems',name:'ear_hearing_problems'},
          {label:'5. Heart disease, heart attack, bypass, or other',name:'heart_disease'},
          {label:'6. Pacemaker,stents, implantable devices, or other heart procedures',name:'pacemaker_stents_implantable_devices'},
          {label:'7. High blood pressure',name:'high_blood_pressure'},
          {label:'8. High cholesterol',name:'high_cholesterol'},
          {label:'9. Chronic (long-term) cough, shortness of breath, or other breathing problems',name:'chronic_breathing_problems'},
          {label:'10. Lung disease (e.g., asthma)',name:'lung_disease'},
          {label:'11. Kidney problems, kidney stones, or pain w/ urination',name:'kidney_problems'},
          {label:'12. Stomach, liver, or digestive problems',name:'digestive_problems'},
          {label:'13. Diabetes or blood sugar problems Insulin used',name:'diabetes_insulin'},
          {label:'14. Anxiety, depression, other mental problems',name:'mental_problems'},
          {label:'15. Fainting or passing out',name:'fainting_passing_out'},
          {label:'16. Dizziness, headaches, numbness, tingling',name:'dizziness_headaches'},
          {label:'17. Unexplained weight loss',name:'unexplained_weight_loss'},
          {label:'18. Stroke, mini-stroke (TIA), paralysis, or weakness',name:'stroke_paralysis_weakness'},
          {label:'19. Missing or limited use of arm, hand, finger, leg, foot, toe',name:'missing_limited_use_of_limbs'},
          {label:'20. Neck or back problems',name:'neck_back_problems'},
          {label:'21. Bone, muscle, joint, or nerve problems',name:'bone_muscle_joint_nerve_problems'},
          {label:'22. Blood clots or bleeding problems',name:'blood_clots_bleeding'},
          {label:'23. Cancer',name:'cancer'},
          {label:'24. Chronic (long-term) infection or other',name:'chronic_infection'},
          {label:'25. Sleep disorders, pauses in breathing while asleep, daytime sleepiness, loud snoring',name:'sleep_disorders'},
          {label:'26. Have you ever had a sleep test (e.g., sleep apnea)?',name:'had_sleep_test'},
          {label:'27. Have you ever spent a night in the hospital?',name:'hospital_stay'},
          {label:'28. Have you ever had a broken bone?',name:'had_broken_bone'},
          {label:'29. Have you ever used or do you now use tobacco?',name:'tobacco_use'},
          {label:'30. Do you currently drink alcohol?',name:'alcohol_use'},
          {label:'31. Use of an illegal substance within the past 2 years',name:'illegal_substance_use'},
          {label:'32. Have you ever failed a drug test or been dependent?',name:'drug_test_failure'}
        ];
    }

    componentDidMount = () => {
        const { visitId } = this.props;
        this.fetchVisitDetails(visitId);
        this.fetchVisitCategory();
        this.fetchVisitStatus();
        this.fetchTimeline(visitId);
    };
   
        generateTimeIntervals = (searchQuery = '')=> {
        const intervals = [];
        const startTime = 0; // Start time in minutes (0 represents 12:00 AM)
        const endTime = 24 * 60; // End time in minutes (24 hours * 60 minutes)
    
        for (let minutes = startTime; minutes < endTime; minutes += 30) {
            const hour = Math.floor(minutes / 60) % 12 || 12;
            const minute = (minutes % 60).toString().padStart(2, '0');
            const period = minutes < 12 * 60 ? 'AM' : 'PM';
            const time = `${hour}:${minute} ${period}`;
    
            // Filter based on search query
            if (time.toLowerCase().includes(searchQuery.toLowerCase())) {
                intervals.push(time);
            }
        }
    
        return intervals;
    }

    setAllToNo = () => {
      const { formData } = this.state;
      const updatedFormData = { ...formData };
  
      // Loop through each question in the patient health information section
      this.patientHealthInformation.forEach(question => {
          updatedFormData[question.name] = 'No'; // Set the value to "No"
      });
      const fieldsToSetNo = ['surgery_history', 'taking_medications', 'other_conditions', 'additional_comments'];
      fieldsToSetNo.forEach(fieldName => {
        updatedFormData[fieldName] = 'No'; // Set the value to "No"
      }); 

      const fieldsToClear = ['surgery_explanation', 'taking_medication_explanation', 'other_conditions_description', 'additional_comments_description'];
      fieldsToClear.forEach(fieldName => {
        updatedFormData[fieldName] = ''; // Set the value to "No"
      });
      // Update the state with the modified form data
      this.setState({ formData: updatedFormData,
        isTextAreaEnabled: {
          ...this.state.isTextAreaEnabled,
          additional_comments_description: true,
          surgery_explanation: true,
          other_conditions_description: true,
          taking_medication_explanation: true,
      },
      });
    };

    editConsentHandler = (fetch = false) => {
      if (fetch) {
        this.props.modalToogleHandler(null,null,null,null,true);
      }
      this.setState({ isEditConsentModelOpen: !this.state.isEditConsentModelOpen });
    };
    
    printConsentHandler = async (visitId = '',snapEmployeeDetails=null)=>{
      const { Loader } = this.props;
      const visitCategory =  this.state.formData.visit_category;
      const employee_id = snapEmployeeDetails.employee_id;
      Loader(true);
        try{
            const data = await ConsentFormService.getVisitConsentPDF(isSet(OrganizationService.getOrgId(),null),visitId,visitCategory,employee_id);
            const pdfData = {
                file_content:data,
                original_name:`visit-consent`,
                mime_type:'application/pdf'
                
            }
            FileManagerService.printPdf(pdfData);
        }catch(e){
          console.log('e',e);
        }
      Loader(false);
    }

    render() {
        const { visitId,modalToogleHandler,isOpen,fetchDetails} = this.props;
        const { organizationLogo,isEditConsentModelOpen,activeTab, formData ,validations,showConsentButtons,snapClinicConsentForm ,snapVisitConsentForm,snapCompanyDetails,snapEmployeeDetails ,visitCategoryOptions,visitStatusOption} = this.state;
        const hasEditAccess=acl_check(this.props.profile.permissions,[PERMISSIONS.VISITS.EDIT,PERMISSIONS.APPOINTMENT.EDIT]);
        const inputFields = [
            {
              label:'Visit Details',
              data:[
                {
                type: "TextInput",
                label: "Pre-reg Code",
                placeholder: "Pre-reg Code",
                className: "form-control form-control-solid",
                name: "pre_reg_code",
                readOnly:true,
              },
              {
                type: "TextInput",
                label: "Registration",
                placeholder: "Registration",
                className: "form-control form-control-solid",
                name: "registration",
                value:"In clinic",
                readOnly:true,
              },
              {
                type: "SelectList",
                label: "Visit category",
                options:visitCategoryOptions,
                placeholder: "",
                className: "form-control form-control-solid",
                name: "visit_category",
                required: true,

              },
              {
                type: "SelectList",
                label: "Visit Status",
                placeholder: "Visit Status",
                options:visitStatusOption,
                className: "form-control form-control-solid",
                name: "visit_status",
                required:true
              },
              {
                type: "Date",
                label: "Visit Date",
                className: "form-control form-control-solid",
                name: "visit_date",
                required: true,
              },
              {
                type: "SelectSearchList",
                label: "Visit Time",
                // col:'col-6',
                placeholder: "Visit Time",
                isAsync:true,
                options:[{'label':formData.visit_time,value:formData.visit_time}],
                // options:[{'label':getCurrentTime(),value:getCurrentTime()}],
                loadCallBack:(val,callback)=>fetchTimeInterval(val,callback),
                className: "form-control form-control-solid",
                name: "visit_time",
              },
              // {
              //   type: "TextInput",
              //   label: "Cup ID",
              //   placeholder: "Cup ID",
              //   className: "form-control form-control-solid",
              //   name: "cup_id",
              // },
              {
                type: "TextArea",
                label: "Reason for Today's Visit",
                placeholder: "If Injury: Describe in Detail How the Injury Occurred and Body Part Injured",
                className: "form-control form-control-solid",
                name: "visit_reason",
              },
              ]
            },
            formData.visit_category ==="Employer Services - Physicals" && {
                label:'Health Information',
                data:[
                  {
                    type:'button',
                    name:'Set all to No',
                    col:'col-form-label ml-1',
                  },
                    {
                    label:'Have you ever had surgery ?',
                    type:'RadioGroup',
                    name:'surgery_history',
                    col:'col-sm-6 f-400 text-sm  mb-0 opacity-75',
                    options:[{label:'Yes',value:'Yes',labelStyle:{  color: 'red'},inputStyle:{backgroundColor: 'red', borderColor: 'red'}}
                            ,{label:'No',value:'No'}
                            ,{label:'Not Sure',value:'Not Sure',labelStyle:{  color: 'red'},inputStyle:{backgroundColor: 'red', borderColor: 'red'}}],
                    labelStyle:{  color: '#231B68'} ,          
                    id:'surgery_history',
                },
  
                {
                    label:'',
                    type:'TextArea',
                    col:'col-6',
                    disabled:this.state.isTextAreaEnabled.surgery_explanation,
                    isLabel:false,
                    name:'surgery_explanation',
                    
                },
                
                {
                    label:"Are you currently taking medications (prescription, over-the-counter, herbal remedies, diet supplements)?",
                    type:'RadioGroup',
                    col:'col-sm-6 f-400 text-sm  mb-0 opacity-75',
                    labelWrap:false,
                    name:'taking_medications',
                    options:[{label:'Yes',value:'Yes',labelStyle:{  color: 'red'},inputStyle:{backgroundColor: 'red', borderColor: 'red'}}
                            ,{label:'No',value:'No'}
                            ,{label:'Not Sure',value:'Not Sure',labelStyle:{  color: 'red'},inputStyle:{backgroundColor: 'red', borderColor: 'red'}}],
                    labelStyle:{  color: '#231B68'} ,       
                    id:'taking_medications',
                },
                
                {
                    label:'',
                    type:'TextArea',
                    col:'col-6',
                    disabled:this.state.isTextAreaEnabled.taking_medication_explanation,
                    name:'taking_medication_explanation',
                    
                },
                {
                    label:'Do you have or have you ever had:',
                    type:'Label'
                },                
                {
                  col:'col-12',
                  label:'',
                  // defaultBtnLabel:'Set all to No',
                  type:'YesOrNoTable',
                  options:[{label:'Yes',value:'Yes'},{label:'No',value:'No'},{label:'Not Sure',value:'Not Sure'}],
                  defaultValues:this.patientHealthInformation.reduce((acc, itm) => {
                      acc[itm.name] = 'No';
                      return acc;
                  }, {}),
                  failedValue:['Yes','Not Sure'],
                  list:this.patientHealthInformation,
                  
              },
                {
                    label:'Other health condition(s) not described above ?',
                    type:'RadioGroup',
                    name:'other_conditions',
                    col:'col-sm-6 f-400 text-sm  mb-0 opacity-75',
                    options:[{label:'Yes',value:'Yes',labelStyle:{  color: 'red'},inputStyle:{backgroundColor: 'red', borderColor: 'red'}}
                            ,{label:'No',value:'No'}
                            ,{label:'Not Sure',value:'Not Sure',labelStyle:{  color: 'red'},inputStyle:{backgroundColor: 'red', borderColor: 'red'}}],
                    labelStyle:{  color: '#231B68'} ,   
                    id:'other_conditions',
                },
                // {
                //     type:'Blank',
                //     col:'col-6',
                // },
                {
                    label:'',
                    type:'TextArea',
                    col:'col-6',
                    disabled:this.state.isTextAreaEnabled.other_conditions_description,
                    name:'other_conditions_description',
                    
                },
                
                {
                    label:"Did you answer 'yes' to any of questions 1-32? If so, please comment further on those health conditions below.",
                    type:'RadioGroup',
                    col:'col-sm-6 f-400 text-sm  mb-0 opacity-75',
                    name:'additional_comments',
                    options:[{label:'Yes',value:'Yes'}
                            ,{label:'No',value:'No'}
                            ,{label:'Not Sure',value:'Not Sure'}],
                    labelStyle:{  color: '#231B68'} ,  
                    id:'additional_comments',
                },
                // {
                //     type:'Blank',
                //     col:'col-6',
                // },
                {
                    label:'',
                    type:'TextArea',
                    col:'col-6',
                    disabled:this.state.isTextAreaEnabled.additional_comments_description,
                    name:'additional_comments_description',
                    
                },
              ]
            }
            // {
            //     label:'More Information',
            //     data:[]
            // }
          ];

        return (
            <>
            {
              isEditConsentModelOpen ? 
              <EditClinicVisitCategoryConsentForm
                  isOpen={true}
                  visitId={visitId}
                  snapClinicConsentForm={snapVisitConsentForm !=null ? snapVisitConsentForm : snapClinicConsentForm}
                  visit_category={formData.visit_category}
                  snapEmployeeDetails={snapEmployeeDetails}
                  snapCompanyDetails={snapCompanyDetails}
                  editConsentHandler={this.editConsentHandler}
                  organizationLogo={organizationLogo}

              /> : 
            
              <Modal
              show={isOpen}
              onHide={() => this.modelCloseHandler()}
              size="xl"
              dialogClassName="hResponsive"
              contentClassName="h-100"
              backdrop="static"
              keyboard={false}
            >
                <div className="sh_modalBox">
                <Modal.Header  closeButton className="p-2">
                  <div className="tabs-wrapper">
                    <ul className="nav nav-borders nav-tabs border-0" role="tablist">
                      {MAIN_TABS.map((item, index) => (
                        <li key={index}>
                          <a
                            href="#!"
                            onClick={() => this.setActiveTabHandler(index)}
                            className={`cursor-pointer nav-link ${index === activeTab ? "active" : ""}`}
                          >
                            {item.label}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <div className="d-flex justify-content-end align-items-center w-50">
                    {showConsentButtons ? <div className="row-2 justify-content-end align-items-center ml-3 px-3" >
                      {/* <Buttons
                      clickHandler={() =>
                        this.printConsentHandler(visitId,snapEmployeeDetails)
                      }
                        className="btn btn-primary"
                        acl={"any"}
                        label={"Print Consent"}
                      />{' '} 
                      <Buttons
                      clickHandler={() =>
                        this.editConsentHandler()
                      }
                        className="btn btn-primary"
                        acl={"any"}
                        label={"Edit Consent"}
                      /> */}
                      <div className="btn-group pb-1">
                      <Buttons
                        clickHandler={() =>
                          this.printConsentHandler(visitId,snapEmployeeDetails)
                          }
                            className="btn btn-primary"
                        acl={"any"}
                        label={" Consent"}
                      />
                      <button
                        className="btn btn-outline-secondary dropdown no-arrow"
                        data-bs-toggle="dropdown" style={{ zIndex: "3" }}
                      >
                        <span className="dropdown-toggle">
                          <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                        </span>
                        <div
                          className="dropdown-menu dropdown-menu-right shadow"
                          aria-labelledby="userDropdown"
                        >
                          <a className="dropdown-item" href="!#" onClick={() =>  this.printConsentHandler(visitId,snapEmployeeDetails)}>
                          Print Consent
                          </a>
                          <a className="dropdown-item" href="!#"  onClick={() =>   this.editConsentHandler()}>
                            Edit Consent
                          </a>
                        </div>
                      </button>
                    </div>
                    </div> : <></>}
                    </div>
                  </div>
                  
                    </Modal.Header>
                    <Modal.Body style={{ background: "#eef2f8" }}>
                    
                    

                    <div className="card-body tab-content p-0">
                    {MAIN_TABS.map((item, index) => {
                      const TabComponent = item.component;
                      return (
                        <div key={index} className={`tab-pane ${index === activeTab ? "show active" : ""}`}>
                          <TabComponent
                            {...this.props}
                            hasEditAccess={hasEditAccess}
                            inputFields={inputFields}
                            formData={formData}
                            validations={this.state.validations}
                            onChangeHandler={this.onChangeHandler}
                            timelineData={this.state.visitTimeline}
                            setAllToNo={this.setAllToNo}
                          />
                        </div>
                      );
                    })}
                  </div>
                  </Modal.Body>
                    <div className="d-flex justify-content-center modal-footer">
                      <Acl acl={PERMISSIONS.VISITS.EDIT}>
                        <Buttons
                          type="Single"
                          className="btn btn-primary"
                          clickHandler={this.updateVisitDetails}
                          label="Update"
                          acl={PERMISSIONS.VISITS.EDIT}
                        />
                      </Acl>
                    </div>
                  </div>
            </Modal>
            
            }
            {
              this.state.showAlert==true&&
                createPortal(
                  <Alert
                    onDelete={this.props.modalToogleHandler}
                    onClose={this.closeHandler}
                    content={
                      "Are you sure you want to cancel? This action will not save any of the changes made."
                    }
                    yesButtonName={"Confirm"}
                    noButtonName={"Cancel"}
                    type="warning"
                  />,
                  document.getElementsByClassName("sh_modalBox")[0]
                )}
            </>
            
        );
    }

    setActiveTabHandler = (tabIndex) => {
        this.setState({
            activeTab: tabIndex
        });
    };


    fetchVisitDetails = async (visitId) => {
        const { Loader } = this.props;
       Loader(true);
        try {
            const data = await VisitsService.getVisits(visitId);
            const formData = {
              registration:'In clinic',
                ...data[0],
                ...(data[0].patient_health_template ? { ...data[0].patient_health_template } : {}),
            };

            
            this.setState({
                formData,
                
                isTextAreaEnabled: {
                    ...this.state.isTextAreaEnabled,
                    additional_comments_description: formData.additional_comments !== 'Yes',
                    surgery_explanation: formData.surgery_history !== 'Yes',
                    other_conditions_description: formData.other_conditions !== 'Yes',
                    taking_medication_explanation: formData.taking_medications !== 'Yes',
                },
                showConsentButtons:formData.snapClinicConsentForm != null || formData.snapVisitConsentForm != null,
                snapClinicConsentForm:formData.snapClinicConsentForm,
                snapVisitConsentForm:formData.snapVisitConsentForm,
                snapCompanyDetails:formData.company,
                snapEmployeeDetails:formData.employee,
                organizationLogo:formData.organizationLogo,
              });
            
            Loader(false);
        } catch (error) {
            Loader(false);
            console.error("Error fetching employee details:", error);
        }
    };

    updateVisitDetails = async () => {
        const { Loader } = this.props;
        Loader(true);
        const {formData ,snapEmployeeDetails}=this.state;
        const { visitId } = this.props;
        let payload={
            employee_id:formData.employee_id,
            visit_category:formData.visit_category,
            visit_date:formData.visit_date,
            visit_reason:formData.visit_reason,
            visit_time:formData.visit_time,
            visit_status:formData.visit_status,
            registration:formData.registration,
            // cup_id:formData.cup_id,

        }

        if (formData.visit_category === "Employer Services - Physicals") {
          const healthInformation = {
              patient_id:snapEmployeeDetails.employee_id,
              company_id:snapEmployeeDetails.company_id,
              visit_mapping_id:null,
              surgery_history: formData.surgery_history ?? null ,
              surgery_explanation: formData.surgery_explanation ?? null ,
              taking_medications: formData.taking_medications ?? null ,
              taking_medication_explanation: formData.taking_medication_explanation ?? null ,
              other_conditions: formData.other_conditions ?? null ,
              other_conditions_description: formData.other_conditions_description ?? null ,
              additional_comments: formData.additional_comments ?? null ,
              additional_comments_description: formData.additional_comments_description ?? null ,
              head_brain_injuries: formData.head_brain_injuries ?? null ,
              seizures_epilepsy: formData.seizures_epilepsy ?? null ,
              eye_problems: formData.eye_problems ?? null ,
              ear_hearing_problems: formData.ear_hearing_problems ?? null ,
              heart_disease: formData.heart_disease ?? null ,
              pacemaker_stents_implantable_devices: formData.pacemaker_stents_implantable_devices ?? null ,
              high_blood_pressure: formData.high_blood_pressure ?? null ,
              high_cholesterol: formData.high_cholesterol ?? null ,
              chronic_breathing_problems: formData.chronic_breathing_problems ?? null ,
              lung_disease: formData.lung_disease ?? null ,
              kidney_problems: formData.kidney_problems ?? null ,
              digestive_problems: formData.digestive_problems ?? null ,
              diabetes_insulin: formData.diabetes_insulin ?? null ,
              mental_problems: formData.mental_problems ?? null ,
              fainting_passing_out: formData.fainting_passing_out ?? null ,
              dizziness_headaches: formData.dizziness_headaches ?? null ,
              unexplained_weight_loss: formData.unexplained_weight_loss ?? null ,
              stroke_paralysis_weakness: formData.stroke_paralysis_weakness ?? null ,
              missing_limited_use_of_limbs: formData.missing_limited_use_of_limbs ?? null ,
              neck_back_problems: formData.neck_back_problems ?? null ,
              bone_muscle_joint_nerve_problems: formData.bone_muscle_joint_nerve_problems ?? null ,
              blood_clots_bleeding: formData.blood_clots_bleeding ?? null ,
              cancer:formData.cancer ?? null ,
              chronic_infection:formData.chronic_infection ?? null ,
              sleep_disorders:formData.sleep_disorders ?? null ,
              had_sleep_test:formData.had_sleep_test ?? null ,
              hospital_stay:formData.hospital_stay ?? null ,
              had_broken_bone:formData.had_broken_bone ?? null ,
              tobacco_use:formData.tobacco_use ?? null ,
              alcohol_use:formData.alcohol_use ?? null ,
              illegal_substance_use:formData.illegal_substance_use ?? null ,
              drug_test_failure:formData.drug_test_failure ?? null ,           
          };
          payload.healthInformation = healthInformation;
        }
    
        const res = await VisitsService.updateVisits(visitId, payload);
        if (res.code === 500) {
          this.props.Toast.error(isSet(res.data, "Something went wrong.."));
        } else if (res.status === "ERROR" && res.data) {
          if (isObject(res.data)) {
            let validaitons = {};
            for (let key in res.data) {
              validaitons = { ...validaitons, [key]: res.data[key][0] };
            }
            this.setState({
                validations:validaitons
            })
          }
        } else {
          this.props.Toast.success("Employee/patient visit updated successfully");
        //   this.editHandler(null, {});
        this.props.fetchDetails();
        this.props.modalToogleHandler();

        }

        Loader(false);
      
    };

    

    onChangeHandler = (fieldName, value, dependencyValues = {}) => {
        const isDisabled = value !== 'Yes';

        let showConsentButtons;
        if(fieldName === 'visit_category' && this.state.formData.visit_category !==value){
            showConsentButtons = false;
        }

        // Determine which text area field to update based on the fieldName
        const textAreaFieldToUpdate =
            fieldName === 'additional_comments'
                ? 'additional_comments_description'
                : fieldName === 'surgery_history'
                ? 'surgery_explanation'
                : fieldName === 'other_conditions'
                ? 'other_conditions_description'
                : fieldName === 'taking_medications'
                ? 'taking_medication_explanation'
                : '';


        this.setState((prevState) => ({
            ...prevState,
            formData: {
              ...prevState.formData,
              [fieldName]: value,
              [textAreaFieldToUpdate]: isDisabled ? null : prevState.formData[textAreaFieldToUpdate],
            },
            validations:{
                ...prevState.validaitons,
            [fieldName]:''
            },
            isTextAreaEnabled: {
              ...prevState.isTextAreaEnabled,
              [textAreaFieldToUpdate]: isDisabled,
            },
            showConsentButtons:showConsentButtons,
            hasDataChanges:true,
          }));
        };
        fetchVisitCategory = async ()=>{
            try{
              const data = await LookupService.getLookupByKey(VISIT_CATEGORY);
              let visitCategoryArr = [{ label: '-None-', value: '' }];
                  data.forEach((item) => {
                    visitCategoryArr.push({
                            label: item.value,
                            value: item.value
                        });
                    });
                this.setState({visitCategoryOptions:visitCategoryArr});
            }catch(e){
            }
          }
          fetchVisitStatus = async ()=>{
            try{
              const data = await LookupService.getLookupByKey(VISIT_STATUS);
              let visitStatusArr = [{ label: '-None-', value: '' }];
                  data.forEach((item) => {
                    visitStatusArr.push({
                            label: item.value,
                            value: item.value
                        });
                    });
                this.setState({visitStatusOption:visitStatusArr});
            }catch(e){
            }
          }
          fetchTimeline=async(visitId)=>{

            const response=await VisitsService.getVisitTimeLine(visitId);
            let updateVisitTimeline=[];
            // visitTimeline
            // const usDateTimeString = ;
            response.forEach((item,itemIndex)=>{
              const momentDate = moment.utc(item.updated_at);
              updateVisitTimeline.push({
                index:itemIndex,
                status:item.visit_status,
                date: momentDate.format('MMMM D, YYYY'),
                time: momentDate.format('h:mm:ss A'),
                updated_by:item.updated_by&& item.updated_by.first_name +" "+item.updated_by.last_name || '--',
              });
            });
            this.setState({visitTimeline:updateVisitTimeline})
          }
          closeHandler=()=>{
            this.setState({
                showAlert:!this.state.showAlert
            });
        }
        modelCloseHandler=()=>{
          const {hasDataChanges}=this.state;
          if (hasDataChanges==true) {
            this.setState({showAlert:true})
          }
          else{
            this.props.modalToogleHandler();
          }
        }
}

export default WithRoutify(withReduxState(VisitDetails));
