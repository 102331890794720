import React, {useMemo, useRef, useState,useEffect} from "react";
import Icons from "../../UI/Icons";
import Buttons from "../../UI/Buttons";
import Acl from "../../../hoc/Acl";
import { Link, useLocation } from "react-router-dom";
import { isSet } from "../../../utils/commonUtils";
import Button from "../../UI/Buttons/Button";
// import Wrap from "../../../hoc/Wrap";
// import { useNavigate } from "react-router-dom"

const Menus = (props) => {
  const {sideBarChangeHandler,currentPath}=props;
  const menuRef = useRef(null);
  const [submenuPosition, setSubmenuPosition] = useState({});
  const menuItemRef = useRef(null);
  const submenuRef = useRef(null);
  const [configureActive,setConfigureActive] = useState(false);
  const location = useLocation();

  const flattenedAclArray = useMemo(()=>{
    return props.Configures.flatMap(item => Array.isArray(item.acl) ? item.acl.flat() : [item.acl]);
  },[props.Configures]);
  

//use effect for active menu configure
  useEffect(() => {
    const isActive = props.Configures.some(menu => location.pathname === menu.link);
    setConfigureActive(isActive);
  }, [location.pathname, props.Configures]);
  const handleClickOutside = (event) => {
    if(isSet(submenuRef.current) && submenuRef.current.classList.contains('show') && !submenuRef.current.contains(event.target)){
      submenuRef.current.classList.remove('show');
    }else if(isSet(event.target.closest('a')) && event.target.closest('a').hasAttribute('data-bs-toggle')){
      props.removeSideBar();
    }
    
    if (
      submenuRef.current &&
      !submenuRef.current.contains(event.target) &&
      menuItemRef.current &&
      !menuItemRef.current.contains(event.target)
    ) {
      
      const parentNodeList = submenuRef.current.parentNode;
      try{
        if(submenuRef.current.classList.contains('show')){
          if(!event.target.closest('a').hasAttribute('data-bs-toggle')){
            parentNodeList.querySelector('a').click();
          }
          
        }
      }catch(e){

      }
      
      // submenuRef.current.parentNode.querySelector('a').click();
    }else if(
      submenuRef.current &&
      menuItemRef.current &&
      !menuItemRef.current.contains(event.target)){
        const parentNodeList = submenuRef.current.parentNode;
        if(event.target.classList.contains('collapse-item')){
          setTimeout(() => {
            parentNodeList.querySelector('a').click();
          }, 500);
        }
      // collapse-item
    }
  };
  useEffect(() => {
    if (menuItemRef.current) {
      const rect = menuItemRef.current.getBoundingClientRect();
      // console.log('rect',rect);
      setSubmenuPosition({ top: (rect.top), left: rect.left });
    }
   

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [props.activeMenu]);
    
  const toggleSidebar = () => {
    document.body.classList.toggle('sidebar-toggled');
    document.querySelector('.sidebar').classList.toggle('toggled');
    if (document.querySelector('.sidebar').classList.contains('toggled')) {
      document.querySelectorAll('.sidebar .collapse').forEach(el => el.classList.remove('show'));
    }
  };

  const handleSubMenuClick = (event) =>{
    if(isSet(submenuRef.current) && submenuRef.current.classList.contains('show') && submenuRef.current.contains(event.target)){
      submenuRef.current.classList.remove('show');
    }
  }

  // console.log('submenuPosition',submenuPosition);
  return (
    <aside ref={menuRef}>
      <ul
        className="navbar-nav sidebar sidebar-dark accordion toggled"
        id="accordionSidebar"
      >
         {/* <!-- Sidebar Toggle (Topbar) */}
                  <li className="nav-item">
                      <a id="sidebarToggleTop" onClick={toggleSidebar} className="btn btn-link rounded-circle mr-3">
                        {/* <i className="fa fa-bars"></i> */}
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" id="menu-fries">
                          <path fill="url(#a)" d="M5 5.25C4.58579 5.25 4.25 5.58579 4.25 6C4.25 6.41421 4.58579 6.75 5 6.75H19C19.4142 6.75 19.75 6.41421 19.75 6C19.75 5.58579 19.4142 5.25 19 5.25H5Z"></path>
                          <path fill="url(#a)" d="M5 11.25C4.58579 11.25 4.25 11.5858 4.25 12C4.25 12.4142 4.58579 12.75 5 12.75H11C11.4142 12.75 11.75 12.4142 11.75 12C11.75 11.5858 11.4142 11.25 11 11.25H5Z"></path>
                          <path fill="url(#a)" d="M5 17.25C4.58579 17.25 4.25 17.5858 4.25 18C4.25 18.4142 4.58579 18.75 5 18.75H19C19.4142 18.75 19.75 18.4142 19.75 18C19.75 17.5858 19.4142 17.25 19 17.25H5Z"></path>
                          <defs>
                            <linearGradient id="paint0_linear_1_11499" x1="12" x2="12" y1="5.25" y2="18.75" gradientUnits="userSpaceOnUse">
                              <stop stop-color="#834D9B"></stop>
                              <stop offset="1" stop-color="#D04ED6"></stop>
                            </linearGradient>
                            <linearGradient id="paint1_linear_1_11499" x1="12" x2="12" y1="5.25" y2="18.75" gradientUnits="userSpaceOnUse">
                              <stop stop-color="#834D9B"></stop>
                              <stop offset="1" stop-color="#D04ED6"></stop>
                            </linearGradient>
                            <linearGradient id="paint2_linear_1_11499" x1="12" x2="12" y1="5.25" y2="18.75" gradientUnits="userSpaceOnUse">
                              <stop stop-color="#834D9B"></stop>
                              <stop offset="1" stop-color="#D04ED6"></stop>
                            </linearGradient>
                          </defs>
                        </svg>
                      </a>
                  </li>
        {/* Sidebar - Brand  */}
        <a
          className="sidebar-brand "
          // href="#"
          role="button"
          style={{ cursor: 'default' }} 
        >
          <div className="sh_logoIcon">
            <div className="sidebar-brand-icon">
                {/* <Link to="/dashboard">  */}
                <Icons type={`Logo`} />
                {/* </Link> */}
                
            </div>
          </div>
        </a>
        {props.MenusData.map((menu, index) => {
          const isActive = (props.activeMenu===menu.title)?'sh_activeMenu':'';
          let refObj = {};
          if(isSet(menu.subMenu) && menu.subMenu.length>0){
            refObj = {
              ref:menuItemRef
            }
          }
          return (
            <Acl acl={menu.acl} key={`acl-menu${index}`}>
              <li className={`nav-item ${isActive}`} {...refObj} key={index} onClick={()=>props.setActiveMenu(menu.title)}>
                <Buttons className="nav-link custom-collapse-toggle" href={isSet(menu.link,'#!')} acl={'any'} extraAttr={isSet(menu.attr,{})}>
                    <div className="sh_sidebarnav_item">
                      <span className="sh_menuIcon">
                        <Icons type={menu.iconType} /> 
                      </span>
                      <span className="text-gray-600 sh_sidebarnav_title">{menu.title}</span>
                    </div>
                </Buttons>
                {(isSet(menu.subMenu,[]).length>0) &&
                      <div id={menu.subMenuId}  ref={submenuRef} className="collapse submenu-Inner active" style={{ 
                        top: submenuPosition.top,
                        position:'absolute !important',
                        left: submenuPosition.left}}>
                        <div className="collapse-inner mb-0" onClick={handleSubMenuClick}>
                          {isSet(menu.subMenu,[]).map((subMenuItem,subMenuIndex)=>{
                            const isSubmenuActive=currentPath===subMenuItem?.link
                              return(
                                <Acl acl={subMenuItem.acl} key={`acl-menu-submeny${subMenuIndex}`}>
                                  <Button className={`collapse-item side_InnerText ${isSubmenuActive?'active':''}`}  title={subMenuItem.title} key={subMenuIndex} href={subMenuItem.link}  acl={'any'}>{subMenuItem.title}</Button>
                                </Acl>
                              )
                          })}
                        </div>
                      </div>
                    }
              </li>
            
            </Acl>
          );
        })}
        
      </ul>
      <Acl acl={flattenedAclArray}>
      <div className={`nav-item menu_Settings border-top ${configureActive==true?'sh_activeMenu':''}`}>
          <a
            className="nav-link js-menu-toggle no_arrow"
            href={"#"}
            onClick={(e) =>{sideBarChangeHandler();}}
            data-bs-toggle="collapse"
          >
            <div className="sh_sidebarnav_item">
            <span className="sh_menuIcon d-block">
              <Icons type="Settings" />
            </span>
            <span className="text-gray-600 sh_sidebarnav_title">Configure</span>
            </div>
          </a>
        </div>
      </Acl>
      <div className="side_Inner">
        <div className="nav-link">
          {props.Configures.map((item, index) => {
            const isActive = (props.activeMenu===item.Title)?'active':'';
            return(
              <li style={{'listStyle':'none'}} key={index}  onClick={()=>{props.setActiveMenu(item.Title)}}>
              <Buttons 
                acl={item.acl}
                accessType={'Hide'} 
                href={item.link} 
                className={`side_InnerText ${isActive}`} 
                key={index} 
                label={item.Title}/>
                </li>
              
            )
          })}
        </div>
        
      </div>
    </aside>
  );
};

export default Menus;
