import React from 'react';

const LoaderIcon = (props) => {
  return (
    <>
    <div class="sh_loader">
    <span className='sh_loaderlogo'><svg xmlns="http://www.w3.org/2000/svg" width="46.701" height="50.625" viewBox="0 0 46.701 50.625" > 
        <path class="a" fill="#fdfdfe"  d="M50.794,127.021c3.083.373,4.983,1.019,6.449,4.247l.1.226c1.734-2.873-.1-5.691-3.5-7.227-2.9-1.307-8.5-.519-9.232.259a2.884,2.884,0,0,0-.532,3.489C45.306,127.242,48.843,126.786,50.794,127.021Zm-8.229-8.88c2.265-2.228,7.963-2.319,11.858-1.5a9.448,9.448,0,0,1,5.5,3.742,6.029,6.029,0,0,1,.65,1.383c1.27-.025,10.439-.242,12.916-1.52.519-.267,1.3-.73.993-2.376-.379-2.023-8.192-2.248-9.644-2.361-6.046-.475-17.531.053-17.531.053,1.572-3.87,4.254-9.827,3.644-13.962-.215-1.47-1.617-3.207-2.921-1.69-.173.4-.864,2.054-1.856,4.009-3.26,6.455-5.377,7.308-9.791,12.343-8.28,9.448-5.246,21.3,10.718,21.911a13.988,13.988,0,0,0,6.437-1.444c3.717-2.022,2.075-5.941-.346-7.1-1.838-.878-6.729-.756-8.033.114-.041.028-2.137,3.717,4.423,3.43l.086,2.038c-6.259.274-7.322-3-6.773-5.264a5.188,5.188,0,0,1-.669-5.6A4.634,4.634,0,0,1,42.565,118.141ZM44,119.6a2.415,2.415,0,0,0-.364,3.128c2.2-1.36,8.056-1.661,11.045-.314a10.2,10.2,0,0,1,4.011,3.077C60.7,117.094,46.552,117.078,44,119.6Zm3.032,20.613c-17.807-.685-21.5-14.655-12.176-25.288,4.254-4.854,6.406-5.778,9.509-11.92,1.1-2.174,1.842-4,1.842-4a3.418,3.418,0,0,1,4.343-1.326c5.134,2.009,1.111,12.368-.173,15.724,3.068-.105,9.972-.281,14.631.086,2.784.22,10.769.182,11.486,4.017,1.088,5.806-6.688,5.792-10.3,6.056-2.3.169-4.4.228-5.282.248a9.951,9.951,0,0,1-.768,3.824c-.086.2-.161.376-.22.5a6.58,6.58,0,0,1-2.079,6.023C57.494,138.543,50.871,140.355,47.028,140.208Z" transform="translate(-29.89 -89.591)">  </path>

        <path fill="#fdfdfe" class="a" d="M370.888,16.371a16.885,16.885,0,0,0-10.538-6.12l.513-3.07a20.047,20.047,0,0,1,16.863,20.55l-3.121-.165A16.868,16.868,0,0,0,370.888,16.371Z" transform="translate(-331.781 -7.18)"><animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.1"></animate></path>

        <path fill="#fdfdfe" class="b" d="M357.086,81.158a11.426,11.426,0,0,0-7.166-4.149l.443-2.649A14.156,14.156,0,0,1,362.3,88.8l-2.688-.144A11.423,11.423,0,0,0,357.086,81.158Z" transform="translate(-322.253 -68.553)"><animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.2"></animate></path>

        <path fill="#fdfdfe" class="c" d="M342.575,142.855a5.705,5.705,0,0,0-3.625-2.075l.471-2.821A8.586,8.586,0,0,1,346.7,146.6l-2.863-.15A5.694,5.694,0,0,0,342.575,142.855Z" transform="translate(-312.231 -126.655)"><animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.3"></animate></path> 
    </svg></span>
  </div>
    </>
  );
};

export default LoaderIcon;
