import React, { useEffect, useState} from "react";
import { useNavigate  } from "react-router-dom";
import { _t } from "../../../utils/i18nUtils";
import AuthService from "../../../services/AuthService";
import Wrap from "../../../hoc/Wrap";
import { useDispatch, useSelector } from 'react-redux';
import Icons from "../../UI/Icons";
import { activeProfileDetails, removeProfile, setProfileDetails } from "../../../store/SessionSlice";
import { capitalizeFirstLetter, isSet} from "../../../utils/commonUtils";
import WithRoutify from "../../../hoc/WithRoutify";
import OrganizationService from "../../../services/OrganizationService";
import FormInputs from "../../UI/FormInputs";
import { reloadPropsAction } from "../../../store/CommonSlice";
import { v4 as uuidv4 } from 'uuid';
import { fetchGlobalOrganization, organizationList } from "../../../store/OrganizationSlice";
import UserService from "../../../services/UserService";
import Button from "../../UI/Buttons/Button";
import { acl_check, isOrganizationAdmin } from "../../../utils/aclUtils";
import withReduxState from "../../../hoc/wReduxState";
import TimeZone from "../../UI/Timzone";
import { fetchNotificationStatus, notificationStatus } from "../../../store/NotificationSlice";
import GlobalSearch from "./GlobalSearch/GlobalSearch";
import { resetGlobalFilter } from "../../../store/GlobalFilterSlice";
import { resetDD } from "../../../store/DropDownSlice";
const orgMenusFilter = ['/company','/labs','/employee','/clinics','/providers','/clincs-department','/division','/consentForm','/vaccine-administration','/laboratory','/csv-import','/result/physical-drug-screens','/result/vaccine','/contract/pricing'];
const Header = ({Toast,Loader,...props})=>{
    const organizationListOptions = useSelector(organizationList);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const profileDetails = useSelector(activeProfileDetails);
    const [isDarkMode,setIsDarkMode]=useState(profileDetails?.prefers_dark_mode);
    const {pendingCount} = useSelector(notificationStatus);
     const globalSearchFilter = ['/visits','/appointments','/white-board','/labs','/result/vaccine','/result/physical-drug-screens','/employee'];
    let name = '';
    if (profileDetails) {
    if (profileDetails.hasOwnProperty('name')) {
        name = profileDetails.name;
    } else {
        name = `${profileDetails.first_name || ''} ${profileDetails.last_name || ''}`;
    }
    }
    // console.log('profiledetails',profileDetails);
    
    const {pathname}=props.location

    const onlogoutHandler =  () => {
        try {
              dispatch(removeProfileAsync(AuthService.getProfileId()));
              dispatch(resetGlobalFilter());
              dispatch(resetDD()); //reset filter dropdwn  
          } catch (error) {
            // Handle errors if necessary
          }
    };
    useEffect(() => {
        dispatch(fetchGlobalOrganization(profileDetails));
      }, [profileDetails]);

      useEffect(() => {
        if (isDarkMode) {
            document.body.setAttribute('data-theme', 'dark');
        } else {
            document.body.removeAttribute('data-theme'); 
        }
      }, [isDarkMode]);

      useEffect(() => {
        const handleResize = () => {
          if (window.innerWidth < 768) {
            document.querySelectorAll('.sidebar .collapse').forEach(el => el.classList.remove('show'));
          }
          
          else if (window.innerWidth < 480 && !document.body.classList.contains('sidebar-toggled')) {
            document.body.classList.add('sidebar-toggled');
            document.querySelector('.sidebar').classList.add('toggled');
            document.querySelectorAll('.sidebar .collapse').forEach(el => el.classList.remove('show'));
          }
        };
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      },[] );

    // Assuming removeProfile is a thunk action creator
    const removeProfileAsync = (key) => {
        Loader(true);
        return async (dispatch, getState) => {
            const sessionState = getState().session.profileDetails;
            try{
                if(Object.keys(sessionState).length>1){
                    await AuthService.impersonateLogout();
                }
                dispatch(removeProfile({ key }));
            }catch(e){

            }
        // Use getState() to check if the state has been updated
        const updatedState = getState().session.profileDetails;
        let redirectUrl = '/';
        const keys = Object.keys(updatedState);
        try{
            const lastKey = keys[keys.length - 1];
            const updatedProfile = updatedState[lastKey];
            const {permissions,is_multiple_organization} = updatedProfile;
            if(isSet(is_multiple_organization,false)===true && acl_check(permissions,'organization-list')===true){
                redirectUrl = '/organisation';
            }else if(acl_check(permissions,'appointment-list')===true){
                redirectUrl = '/appointments';
            }else if(acl_check(permissions,'company-list')===true){
                redirectUrl = '/company';
            }else if(acl_check(permissions,'clinic-list')===true){
                redirectUrl = '/clinics';
            }else if(acl_check(permissions,'employee-list')===true){
                redirectUrl = '/employee';
            }else if(acl_check(permissions,'visits-list')===true){
                redirectUrl = '/visits';
            }else if(acl_check(permissions,'lab-list')===true){
                redirectUrl = '/labs';
            }else if(acl_check(permissions,'user-list')===true){
                redirectUrl = '/users';
            }else if(acl_check(permissions,'role-list')===true){
                redirectUrl = '/roles';
            }
        }catch(e){

        }
        
        try {
            
            // Asynchronous operations, such as AuthService.logout(), go here
            if(keys.length===0){
                await AuthService.logout();
                Toast.success(profileDetails.name+' logged-out successfully');
                Loader(false);
                navigate('/login');
            }else{
                Toast.success(profileDetails.name+' logged-out successfully');
                Loader(false);
                localStorage.setItem('redirectTo', redirectUrl);
                navigate('/'); 
            }
        } catch (error) {
            Loader(false);
            await AuthService.logout();
            // Handle errors if necessary
        }
        };
    };
  
    const userProfileList = [{
        label:'Profile',
        //fontIcon:'fa-user',
        iconType: "profile2",
        clickHandler:()=>{navigate('/profile')}
    },
    {
        label:'Change Password',
        //fontIcon:'fa-user',
        iconType: "changepassword",
        clickHandler:()=>{navigate('/change-password')}
    },
    {
        label:'Logout',
        isDivider:true,
        clickHandler:()=>onlogoutHandler(),
        //fontIcon:'fa-sign-out-alt'
        iconType: "logout",
    }];

    const darkModeHandler = async () => {
        // Toggle dark mode state
        // Call the API to update the user's dark mode preference
        try {
            const data = await UserService.updateDarkMode(profileDetails.id, {prefers_dark_mode: !isDarkMode});
            dispatch(setProfileDetails({
                key: profileDetails.id,
                value: { ...profileDetails, prefers_dark_mode: data.prefers_dark_mode }
            }));
        } catch (error) {
            console.error("Failed to update dark mode preference", error);
        }
        setIsDarkMode(!isDarkMode);
    };
    // useEffect(() => {
    //     if (process.env.REACT_APP_PRODUCTION_MODE!='false') {
    //         const interval = setInterval(() => {
    //             dispatch(fetchNotificationStatus());
    //         }, 60000);
    //         return () => clearInterval(interval);
    //       }
    // }, [dispatch]);
    return( 
        <>
           <nav className="navbar navbar-expand navbar-light topbar mb-4 fixed-top">
             
               {(orgMenusFilter.indexOf(pathname)!==-1 && isSet(profileDetails,{is_multiple_organization:false}).is_multiple_organization && organizationListOptions.length>2)?
                    <div className="input-group  input-group-joined input-group-solid w-50" style={{margin:'0 25% 0 25%'}}>
                        <label class="input-group-text" style={{background:'#f6f8fa'}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16"> <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"></path></svg>
                        </label>
                        <div className=" form-control" style={{padding:'0', height:'auto'}}>
                        <FormInputs 
                            type="SelectSearchList" 
                            style={{ borderWidth: "0px"}}
                            focusStyle={{border: 'none',boxShadow: 'none'}}
                            className="form-control form-control-solid" 
                            value={isSet(OrganizationService.getOrgId(),'')}
                            options={organizationListOptions} 
                            changeHandler={(val)=>{
                                OrganizationService.storeOrgId(val);
                                OrganizationService.storeTimeZone(val);
                                dispatch(reloadPropsAction(uuidv4()))
                                }} />
                        </div>
                    </div>
             :<>
            
             </>} 
              
             {profileDetails && profileDetails.is_multiple_organization === false && profileDetails.employee_login === false && globalSearchFilter.some(path => pathname.startsWith(path)) ?                    <GlobalSearch
                    activeMenu={props.activeMenu}
                    pathname={pathname}
                    />
                    : <> </>} 
            
                
              <ul className="navbar-nav ml-auto pr-2 profilesect  justify-content-end">
                    {/* {
                        profileDetails.employee_login==true ?
                            <li className="nav-item">
                                <Button
                                    title={"Notification"}
                                    className="nav-link"
                                    iconType={"Notification"}
                                    acl={"any"}
                                    clickHandler={() => navigate('/patient-form-list')}
                                >
                                    {notification.pending>0 && <sup className="badge badge-primary">{notification.pending}</sup>}
                                    
                                </Button>
                               
                                

                            </li>
                            : ''
                    } */}
                    {
                        isOrganizationAdmin(profileDetails.role) ?
                            <li className="nav-item">
                                <Button
                                    title={"Notification"}
                                    className="nav-link"
                                    iconType={"Notification"}
                                    acl={"any"}
                                    clickHandler={props.notificationToogleHandler}
                                >
                                   {pendingCount?<sup className="badge badge-primary notification-icon">{pendingCount}</sup>:<></>}
                                </Button>
                            </li>
                            : ''
                    }
                    {

                        <li className="nav-item dropdown">
                            <a className="nav-link " href="#!" id="userDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <Icons type={'TimeZone'} />
                            </a>
                            <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                                <TimeZone 
                                />
                            </div>
                        </li>
                    }
                {/* <li className="nav-item">
                    <Button
                        title={isDarkMode ? "Switch to Light Mode" : "Switch to Dark Mode"}
                        className="nav-link"
                        iconType={isDarkMode ? "DarkMode" : "LightMode"}
                        acl={"any"}
                        clickHandler={darkModeHandler}
                    />
                </li> */}

                
                 {/* <!-- Nav Item - User Information --> */}
                 <li className="nav-item dropdown">
                       <a className="nav-link dropdown-toggle" href="#!" id="userDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">                     
                           <span className="img-profile rounded-circle">
                           {
                           profileDetails&& isSet(profileDetails.hasOwnProperty("profile_picture")&&profileDetails.profile_picture!==null)?
                            <img
                            className="nav-item rounded-circle"
                            src={profileDetails.profile_picture}
                            alt=""
                            style={{ height: "30px", width: "30px" }}
                            />
                            :
                            <img
                            className="nav-item rounded-circle"
                            src={profileDetails.gravatar_url}
                            onError={(e) => (e.currentTarget.src = profileDetails.default_url)}
                            alt=""
                            style={{ height: "30px", width: "30px" }}
                            />
                           }
                           </span>
                           <span className="ml-2 d-none forTable_textEllipsis  d-lg-inline text-lg" style={{maxWidth:'200px'}} title={(capitalizeFirstLetter(name))}>{_t(capitalizeFirstLetter(name))}</span>
                       </a>
                    {/* <!-- Dropdown - User Information --> */}
                    <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                        {userProfileList.map((item,itemIndex)=>{
                            return(
                                <Wrap key={itemIndex}>
                                {(item.isDivider?<div className="dropdown-divider"></div>:<></>)}
                                <a className="dropdown-item" href="#!" onClick={()=>item.clickHandler()}>
                                    {/* <i className={`fas ${item.fontIcon} fa-sm fa-fw mr-2 text-gray-400`}></i> */}

                                    <Icons type={item.iconType} />
                                    <span className="pl-2"> {_t(item.label)} </span>
                                </a>
                                </Wrap>
                            )
                        })}
                    </div>
                 </li>
                 <li className="nav-item ">
                    <Button
                        title="Log Out"
                        className="nav-link pl-0"
                        iconType= "logout"
                        clickHandler={()=>onlogoutHandler()}
                        acl={"any"}
                    />
                </li>
              </ul>
           </nav>
           
      
        </> 
    )
}
export default  withReduxState(WithRoutify(Header));