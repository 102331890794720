import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { fetchTimeInterval, getCurrentDate, getCurrentTime, getLookupByKey, isObject, isSet } from "../../utils/commonUtils";
import FormInputs from "../../components/UI/FormInputs";
import withReduxState from "../../hoc/wReduxState";
import WithRoutify from "../../hoc/WithRoutify";
import Buttons from "../../components/UI/Buttons";
import CompanyService from "../../services/CompanyService";
import DropDownService from "../../services/DropDownService";
import VisitsService from "../../services/VisitsService";
import ActivitiesService from "../../services/ActivitiesService";
import Wrap from "../../hoc/Wrap";
import { useSelector } from "react-redux";
import { organizationList } from "../../store/OrganizationSlice";
import { VISIT_CATEGORY } from "../../utils/constant";
import EditClinicVisitCategoryConsentForm from "./EditClinicVisitCategoryConsentForm";
import { isCompanyEmployee } from "../../utils/aclUtils";
import { PatientFormModelWithHoc } from "./PatientFormModel";
import { isLaboratoryVisitCategory, isPhysicalVisitCategory } from "../../utils/visitUtils";
import PatientQuestion from "../PatientFormContainer/Forms/PatientQuestion";
import { FieldValidationFocus } from "../../utils/cssUtils";

const AddVisit = (props) => {
  const { Toast,profile } = props;
  const [patientOptions, setPatientOptions] = useState([]);
  const [validation, setValidation] = useState({});
  const [formData, setFormData] = useState({});
  const [activityList, setActivityList] = useState([]);
  const { isOpen, modalToogleHandler, modelTitle } = props;
  const [services, setService] = useState([]);
  const [companyServiceOption, setCompanyServiceOption] = useState([{ label: '-Select Service-', value: '' }]);
  const [physicalServices, setPhysicalServices] = useState([]);
  const [visitCategoryOptions, setvisitCategoryOptions] = useState([]);
  const organizationListOptions = useSelector(organizationList);
  const [companyOptions,setCompanyOption]=useState([]);
  const [showCosentFormModel,setshowConsentFormModel]=useState(false);
  const [showPatientFormModel,setShowPatientFormModel]=useState(false);
  const[showPreRegCode,setShowPreRegCode]=useState(false); 
  const [savedVisit,setSavedVisit]=useState({});
  const [clinicConsentForm,setclinicConsentForm]=useState(null);
  const [patientHealthInformation,setPatientHealthInformation]=useState({});
  const inputFields=getInputFields();
  useEffect(() => {
    const autoFillData = props.autoFillData;
    setFormData({
      company_id: autoFillData.company_id,
      employee_id: autoFillData.employee_id,
      visit_date: isSet(autoFillData.visit_date,getCurrentDate()),
      visit_time: isSet(autoFillData.visit_time,getCurrentTime().toString().padStart(2, '0')),
      service_date: getCurrentDate(),
      standard_services: isSet(autoFillData.visit_date,getCurrentDate()),
    });
    if (isSet(autoFillData.company_id, null) !== null) {
      fetchPatientDetails(autoFillData.company_id,'',()=>{});
      fetchVisitCategoryOptions(autoFillData.company_id);
    }
  }, [props.autoFillData]);
  useEffect(() => {
    fetchActivities();
    
    if(isSet(props.profile) && !isSet(props.profile.role === 'company_employee')){
      fetchCompaniesList();
    }
    fetchVisitCategory();
  }, []);
  const fetchCompaniesList = async () => {
    const response=await DropDownService.getCompanies(null);
        let companiesOptions = [{ label: "-Select Company-", value: "" }];
        response.forEach((company) => {
          companiesOptions.push({
            label: company.name,
            value: company.company_uuid,
          });
        });
        setCompanyOption(companiesOptions);
  };
  const fetchVisitCategory=async()=>{
    const key=await getLookupByKey(VISIT_CATEGORY);
    setvisitCategoryOptions(key);
  }

  
  if (isSet(props.profile.is_multiple_organization, false) === true&& !props.autoFillData.organization_id) {
    inputFields[0].data.unshift(
      {
        type: "SelectList",
        label: "Organization",
        options: organizationListOptions,
        placeholder: "",
        className: "form-control form-control-solid",
        name: "organization",
        value: formData.organization,
      }
    );
  }
  if (isSet(props.profile) && isSet(props.profile.role === 'company_employee')) {
      // Filter out company_id and employee_id fields
      inputFields[0].data = inputFields[0].data.filter(field => field.name !== 'company_id' && field.name !== 'employee_id');
  }
  const fetchActivities = async () => {

    const response = await DropDownService.getActivityDropdownList(null);
    let opt = [{ "label": 'ALL', value: 'ALL' }];
    response.forEach((itm, ind) => {
      opt.push({ label: itm.activity_name, value: itm.actitivity_id,activity_type: itm.activity_type})
    });
    setActivityList(opt);
  }
  const serviceOnchangeHandler = (name, bool, { selectVal }) => {
    let updatedServices = [...services];
    if (bool) {
      if (selectVal === 'ALL') {
        updatedServices = ['ALL'];
      } else {
        updatedServices.push(selectVal);
      }
    } else {
      if (selectVal === 'ALL') {
        updatedServices = [];
      } else {
        let isSelected = services.includes('ALL');
        if (isSelected) {
          updatedServices = activityList.map(itm => itm.value).filter(itm => itm !== selectVal&&itm!='ALL');
        } else {
          updatedServices = updatedServices.filter((value) => value !== selectVal);
        }
      }
    }
    if (activityList.length-1==updatedServices.length) {
      updatedServices = ['ALL'];
    }
    setService(updatedServices);
  };


  const changeHandler = (fieldName, val) => {

    setValidation({ ...validation, [fieldName]: '' })
    
    if (fieldName === 'company_id') {
      setFormData(prevState=>({
        ...prevState,employee_id:props.autoFillData.employee_id,[fieldName]:val
      }));
      fetchPatientDetails(val, '', () => { });
      fetchVisitCategoryOptions(val);

    }else{
      setFormData(prevFormData => {
        let updatedFormData = {
          ...prevFormData,
          [fieldName]: val,
        };
        if (fieldName === 'visit_category' || fieldName === 'company_id') {
          updatedFormData.standard_services='';
        }
        return updatedFormData;
      });
    }
    if (fieldName === 'visit_category') {
      let opt = [{ label: '-Select Service-', value: '' }];

      if (val == 'Employer Services - Physicals') {
        physicalServices.forEach((itm) => {
          opt.push({ label: itm.physical_name, value: itm.company_physical_id })
        });
      }
      if (val == 'Employer Services - Drug Screens') {
        let drugScreeEnabled = physicalServices.filter(item => item.drug_screen == 1);
        drugScreeEnabled.forEach((itm) => {
          opt.push({ label: itm.physical_name, value: itm.company_physical_id })
        });
      }
      setCompanyServiceOption(opt);

    }
  };
  const fetchVisitCategoryOptions = async (company_id) => {
    if (!company_id) return false; 
    let companyPhysical = await CompanyService.getCompaniesPhysical(company_id);
    let opt = [{ label: '-Select Service-', value: '' }];
    const companyPhysicalData=companyPhysical?.data?.data;
    companyPhysicalData.forEach((itm) => {
      opt.push({ label: itm.physical_name, value: itm.physical_name })
    });
    setPhysicalServices(companyPhysicalData);

  }
  const fetchPatientDetails = async (companyId, val, callback) => {
    let optionsArr = [];
    if (companyId) {
      try {
        const data = await CompanyService.getCompaniesEmployee(companyId, val);
        for (const i in data) {
          optionsArr.push({
            label: data[i],
            value: i
          });
        }
        if (optionsArr.length === 0) {
          setPatientOptions([]);
        } else {
          setPatientOptions(optionsArr);
        }
  
        
      } catch (error) {
        // callback(patientOptions);
        // setIsLoading(false);
      } finally {
        // setIsLoading(false);
      }
    }
    callback(optionsArr);
  };


  const formSubmitHandler = async () => {
    
    let updateValidation = {};
    let validationErrors = {};
    let isValid = true;
    if ((!formData.company_id || formData.company_id === null) && isSet(props.profile) && !isSet(props.profile.role === 'company_employee')) {
        validationErrors['company_id'] = `The company id field is required`;
        isValid = false;
    }
    console.log('formdata',formData,hasEzReader());
    if (hasEzReader()&&formData.visitCategory&&!isLaboratoryVisitCategory(formData.visitCategory)) {
      if (!formData.hasOwnProperty('cup_id')||formData.cup_id=='') {
        validationErrors['cup_id'] = `The Cup id field is required`;
        isValid = false;
      }
    }
    if (!isValid) {
        setValidation({
            ...updateValidation,
            ...validationErrors
        });
        FieldValidationFocus(inputFields, validationErrors);
        return false;
    }
    
    let payload = {
      ...formData,
      ['optional_services']: services.join(','),
    };
    //if payload.optional_services have all get all value     
    if (payload.optional_services === "ALL") {
      //get all value
      let allActivityList = activityList.map(itm => itm.value);
      //filter all value 
      let filterAllActivityList = allActivityList.filter(itm => itm != "ALL");
      //append 
      payload = {
        ...formData,
        ['optional_services']: filterAllActivityList.join(',')
      };
    }
    if (formData.visit_category === "Employer Services - Physicals") {
      const healthInformation = {
        ...patientHealthInformation,
        patient_id:formData.employee_id,
        company_id:formData.company_id,
        visit_mapping_id:null,          
      };
      payload.healthInformation = healthInformation;
    }
    if (payload.visit_category) {
      if (isLaboratoryVisitCategory(payload.visit_category)) {
        delete payload.optional_services;
        delete payload.cup_id;
        delete payload.standard_services;
      }
    }
    try {
      props.Loader(true);
      const data = await VisitsService.saveVisits(isSet(formData.organization, null), payload);
      if (data.status && data.status === "ERROR") {
        if (isObject(data.data)) {
          for (let key in data.data) {
            updateValidation = {
              ...updateValidation,
              [key]: data.data[key].join(","),
            };
          }
        } else {
          props.Toast.error(data.data);
        }
        if (Object.keys(updateValidation).length > 0) {
          setValidation(updateValidation);
            FieldValidationFocus(inputFields, updateValidation);         
        }
      } else {
        const savedVisitData = await VisitsService.getVisits(data.visit_id);
        const savedVisit=savedVisitData[0]
        setSavedVisit(savedVisit);
        setclinicConsentForm(savedVisit.snapVisitConsentForm !=null ? savedVisit.snapVisitConsentForm : savedVisit.snapClinicConsentForm);
        if (isCompanyEmployee(props.profile.role)) {
          editConsentHandler(false);
          }
        else{
          patientFormToogleHandler();
        }
        Toast.success('Visit Added Successfully');
      }
      props.Loader(false);
    } catch (e) {

    }

  }
  // console.log('props.profile.role',props.profile.role);
  const patientFormToogleHandler=(closeVisitForm=false)=>{
    if (closeVisitForm) {
      props.fetchVisitsList();
      props.modalToogleHandler(true);
    }
    setShowPatientFormModel(!showPatientFormModel);
  }
  const editConsentHandler=(closeVisitForm=true)=>{
    if (closeVisitForm||clinicConsentForm==null) {
      // if (showPreRegCode==false) {
      //   setshowConsentFormModel(false);
      //   // setShowPreRegCode(true);
      //   // return;
      // }
      props.fetchVisitsList();
      props.modalToogleHandler(true);
    }
    setshowConsentFormModel(!showCosentFormModel);
  }
  const patientHealthInformationChangeHandler=(name,val,formdata)=>{
    if (formdata!=null) {
      setPatientHealthInformation(formdata);
      return;
    }
    setPatientHealthInformation({...patientHealthInformation,[name]:val});
  }
  const hasEzReader = () => {
    if (services) {
      if (services.includes('ALL')) { //if optional service is all
        return true;
      } else {
        const selectedActivity = activityList.filter(activity => services.includes(activity.value));
        if (selectedActivity.some(activity => activity.activity_type === 'Drug Screen - EZ-Reader')) { //if optional service has ez
          return true;
        }
      }
    }
  
    if (formData['standard_services']) {
      const service = physicalServices.find(item => item.company_physical_id === formData['standard_services']);
      if (service) {
        return service.has_ezreader; //standard service has ezreader
      }
    }
  
    return false;
  };
  
  
  
  const VisitCatagoryField = [
    {
      label: 'Standard Services',
      data: [
        {
          label:"Standard Service",
          type: "SelectList",
          options: companyServiceOption,
          className: "form-control form-control-solid",
          col:"col-6",
          name: "standard_services",
          value: formData['standard_services'],
          onchangeHandler: changeHandler
        },
         {
          type: "",
          label:"Cup Id",
          className: "form-control form-control-solid",
          col:"col-6",
          name: "cup_id",
          value: formData['cup_id'],
          placeholder:"Cup ID",
          isShow:hasEzReader(),
          required:true,
          onchangeHandler: changeHandler
        },
      ]
    },
    {
      label: 'Optional Services',
      data: [
        {
          type: "MultiSelectDropDown",
          options: activityList,
          className: "form-control form-control-solid",
          name: "optional_services",
          value: services,
          onchangeHandler: serviceOnchangeHandler

        },

      ]
    }
  ];

  return (
    <>
      {
        showCosentFormModel == true && clinicConsentForm ?
          <EditClinicVisitCategoryConsentForm
            isOpen={true}
            visitId={savedVisit.visit_id}
            snapClinicConsentForm={clinicConsentForm}
            visit_category={formData.visit_category}
            snapEmployeeDetails={savedVisit.employee}
            snapCompanyDetails={savedVisit.company}
            editConsentHandler={editConsentHandler}
            organizationLogo={savedVisit.organizationLogo}

          />
          : ""
      }
      {
        showPreRegCode?
        <Modal 
        show={false}
        // size='xl'
        // dialogClassName='hResponsive'
        contentClassName='h-100'
        backdrop="static"
        keyboard={false}>
          <Modal.Header
          style={{borderBottom : 'none',padding : '0.5rem'}}
          >

          </Modal.Header>
            <Modal.Body>

             <div className="text-center">
             <svg
                        id="Layer_1"
                        width="32px"
                        height="32px"
                        fill="green"
                        data-name="Layer 1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 457.57"
                    >
                        <defs>
                            <style>{".cls-1{fill-rule:evenodd;}"}</style>
                        </defs>
                        <path
                            className="cls-1"
                            d="M0,220.57c100.43-1.33,121-5.2,191.79,81.5,54.29-90,114.62-167.9,179.92-235.86C436-.72,436.5-.89,512,.24,383.54,143,278.71,295.74,194.87,457.57,150,361.45,87.33,280.53,0,220.57Z"
                        />
                    </svg>
               <h6 className="text-lg font-weight-bold mb-2"> Your Pre-Registration is Complete </h6>

              <p>Please take note of the pre-registration code below. When you arrive at the clinic, show the front desk this code to be expedited</p>
              <div className="text-lg font-weight-bold"> {
                savedVisit?.pre_reg_code
              }
              </div>
              </div>
            </Modal.Body>
            <Modal.Footer
            className="d-flex justify-content-center"
            >
            <Buttons
              type='Single'
              className="btn btn-primary"
              clickHandler={()=>editConsentHandler(true)}
              label='Done'
              acl={'any'} 
              />
            </Modal.Footer>
        </Modal>
        :
        ''

      }
      {
        showPatientFormModel ?
          <PatientFormModelWithHoc
            isOpen={showPatientFormModel}
            visitId={savedVisit.visit_id}
            visitData={savedVisit}
            modalToogleHandler={patientFormToogleHandler}
            visitCategory={formData.visit_category}
          />
          :
          <></>}
      <Modal
        show={true}
        onHide={()=>modalToogleHandler(true)}
        size='xl'
        dialogClassName='hResponsive'
        contentClassName='h-100'
        backdrop="static"
        keyboard={false}
      >
        <div className="sh_modalBox">
          <Modal.Header closeButton>
            <Modal.Title>
              <h6 className="text-lg mb-0 font-weight-bold">
                {modelTitle}  for Existing Patient
              </h6>
            </Modal.Title>
            <div className="ml-auto mr-2">{props.headerButton}</div>
          </Modal.Header>
          <Modal.Body
          className="pt-0"
          >
            <div className="row">
              {inputFields &&
                inputFields.map((sec, index) => (
                  <div className='col-12' key={index}>
                    <h6 className="text-md border-bottom pt-3 ">{sec.title}</h6>
                    <div className="row">
                      {sec.data.map((field, fieldIndex) => {
                        if (isSet(field.access, '') == 'HIDDEN') { return false; }
                        const col = isSet(field.col, 'col-6');
                        const value = isSet(formData[field.name], '');
                        return (
                          <div className={`${col}`} key={fieldIndex}>
                            <div className={`form-group ${isSet(field.isLabelAppend, false) === true ? 'form-check form-check-solid' : ''}`} >
                              {(isSet(field.isLabelAppend, false) === false) ?
                                <label className=" text-sm opacity-75 text-truncate mb-0">
                                  {field.label}
                                </label>
                                : <></>}

                              <FormInputs {...field} id={isSet(field.id,field.name)}   changeHandler={(value, e) => changeHandler(field.name, value)} value={value} />
                              {(isSet(field.isLabelAppend, false) === true) ?
                                <label className="form-check-label" htmlFor={field.name}>
                                  {field.label}
                                </label>
                                : <></>}
                              {isSet(validation[field.name], "") !== "" ? (
                                <span className="text-danger m-1">
                                  {isSet(validation[field.name], "")}
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        )

                      })}
                    </div>
                  </div>
                ))}
            </div>
            {!(isSet(props.profile) && isSet(isCompanyEmployee(profile.role))) && formData.visit_category&&!isLaboratoryVisitCategory(formData.visit_category) && VisitCatagoryField.map((section, secIndex) => {
              return (
                <Wrap>
                <h6 className="text-md border-bottom">{section.label}</h6>
                <div className="row">
                  {section.data.map((item, itemIndex) => (
                    isSet(item.isShow, true) && (
                      <div className={isSet(item.col ||"col-lg-12 col-6"  )} key={itemIndex}>
                        <div className="form-group">
                          <div className={item.type === 'MultiSelectDropDown' ? 'd-flex' : ''}>
                            <label className="text-sm opacity-75 text-truncate mb-0">
                              {item.label}
                            </label>
                            <FormInputs
                              {...item}
                              id={isSet(item.id,item.name)}  
                              changeHandler={(...args) => item.onchangeHandler(item.name, ...args)}
                            />
                          </div>
                          {isSet(validation[item.name], "") !== "" ? (
                                <span className="text-danger m-1">
                                  {isSet(validation[item.name], "")}
                                </span>
                              ) : (
                                ""
                              )}
                        </div>
                      </div>
                    )
                  ))}
                </div>
              </Wrap>
              
              )
            })}
          {formData.visit_category&&isPhysicalVisitCategory(formData.visit_category)&&isCompanyEmployee(props.profile.role)&&
           <PatientQuestion
            formData={patientHealthInformation}
            onChangeHandler={(name,val)=>patientHealthInformationChangeHandler(name,val,null)}
            bulkOnChangeHandler={(formData)=>patientHealthInformationChangeHandler(null,null,formData)}
            />
           }
          </Modal.Body>

          <Modal.Footer className="d-flex justify-content-center">
            <Buttons
              type='Single'
              className="btn btn-primary"
              clickHandler={() => formSubmitHandler()}
              label='Save'
              acl={'any'} 
              />
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
  function getInputFields(){
    const inputFields = [
      {
        title: 'Visit details',
        data: [
          {
            type: "SelectList",
            label: "Company",
            placeholder: "Company",
            className: "form-control form-control-solid",
            name: "company_id",
            col: 'col-6',
            access: (props.autoFillData && Object.entries(props.autoFillData).length > 0 && isSet(props.autoFillData.company_id,null)!==null) ? 'HIDDEN' : '',
            options: companyOptions ,
            disabled: (props.autoFillData && Object.entries(props.autoFillData).length > 0 && isSet(props.autoFillData.company_id,null)!==null) ? true : false,
            required: true,
          },
          {
            type: "SelectSearchList",
            label: "Patient/Employee",
            placeholder: "Patient/Employee",
            className: "form-control form-control-solid",
            name: "employee_id",
            col: 'col-6',
            access: (props.autoFillData && Object.entries(props.autoFillData).length > 0 && isSet(props.autoFillData.employee_id,null)!==null) ? 'HIDDEN' : '',
            isAsync: true,
            loadCallBack: (val, callback) => fetchPatientDetails(formData.company_id, val, callback),
            options: patientOptions,
            reload: formData.company_id,
            isCacheNeeded:false,
            readOnly: isSet(formData.company_id, null || (props.autoFillData && Object.entries(props.autoFillData).length > 0  && isSet(props.autoFillData.employee_id,null)!==null)) === null ? true : false,
            required: true,
          },
          {
            type: "Date",
            label: "Visit Date",
            col: 'col-6',
            placeholder: "Visit Date",
            className: "form-control form-control-solid",
            name: "visit_date",
            required: true,
          },
          {
            type: "SelectSearchList",
            label: "Visit Time",
            col: 'col-6',
            placeholder: "Visit Time",
            isAsync: true,
            options: [{ 'label': getCurrentTime(), value: getCurrentTime() }],
            loadCallBack: (val, callback) => fetchTimeInterval(val, callback),
            className: "form-control form-control-solid",
            name: "visit_time",
            reload:formData.visit_time,
          },
          {
            type: "TextArea",
            label: "Reason  for Visit",
            col: 'col-12',
            placeholder: "Reason for Today's Visit (If Injury: Describe in Detail How the Injury Occurred and Body Part Injured)",
            className: "form-control form-control-solid",
            name: "visit_reason",
          },
          {
            type: "SelectList",
            label: "Visit Category",
            placeholder: "",
            className: "form-control form-control-solid",
            name: "visit_category",
            col: 'col-6',
            options:visitCategoryOptions,
            required: true,
          },
        ]
      },
    ];
    return inputFields;
  }
};
AddVisit.defaultProps = {
  headerButton:<></>
}
export default withReduxState(WithRoutify(AddVisit));