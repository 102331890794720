import FormInputs from "../../components/UI/FormInputs";
import { _t } from "../../utils/i18nUtils";
import { useEffect } from "react";
import CustomPasswordStrengthBar from "./CustomPasswordStrengthBar";

const PasswordForm = (props) => {
  let { clearForm, title, formInputs, formData, validation, inputChangeHandler, submitHandler }=props

  const handlePasswordChange = (Score, Feedback) => {
    // console.log("handlePasswordChange,",Score,Feedback);
    validation={...validation,['enterpassword']:Feedback.suggestions}
    // console.log('validation',validation);

  };
  useEffect(()=>{
    clearForm();
  },[title]);

  return (
      <div className="row justify-content-center">
        <div className="col-lg-6 min-vh-100 d-flex flex-column justify-content-center mx-auto">
          <div className="row">
           <div className="col-md-9 mx-auto">
          <div className="card sh_loginCard border-0 rounded-lg mt-5">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-12">
            <div className="text-center sh_loginLogo">
                <img src="/assets/img/snap-logo-new.png" className="w-100" alt="Logo" />
              </div>
              <h6 className="fw-light mb-0">{_t(title)}</h6>
              <form className="sh_loginCre">
                {formInputs.map((formInput, index) => (
                  <div className="mb-3" key={index}>
                    <FormInputs
                      type={formInput.type}
                      placeholder={formInput['placeHolder']}
                      className={`form-control form-control-fields`}
                      name={formInput.name}
                      changeHandler={(value, {e}) => inputChangeHandler(value, e, formInput.name)}
                      value={formInput.value}
                    />
                    {
                      formInput.isPassword&&formInput.isPassword==true&&formInput.value!=''?
                      <CustomPasswordStrengthBar
                      password={formInput.value}
                      onChangeHandler={handlePasswordChange}
                    />
                      :
                    ''}
                    {
                      Array.isArray(validation[formInput.name]) ?
                      validation[formInput.name].map((itm)=>{
                        return(<>
                        <span className="text-danger m-1">{itm}</span><br/>
                        </>)
                      })
                      :
                      <>
                      <span className="text-danger m-1">{validation[formInput.name]}</span><br/>
                      </>
                    }
                    
                  </div>
                  
                ))}
                {props.description&&props.description}
                  
                <div className="d-flex align-items-center justify-content-between sh_loginButtons">
                  <a className="btn btn-primary btn-user" onClick={() => submitHandler()}>
                    {props.submmitButton}
                  </a>       
                  <a className="btn btn-primary btn-user" href="/login">{_t('returnToLogin')}</a>
                </div>
              </form>
              </div>
              </div>
            </div>
           </div>
          </div>
          </div>
        </div>
      </div>
  );
};
export default PasswordForm;